import { DefaultButton, Dialog, DialogFooter, DialogType, DocumentCard, DocumentCardPreview, Icon, IconButton, IDocumentCardPreviewImage, ImageFit, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { add, dismissMessage, setSelectedAccountItems, resetAccountsSet, reset, setIsFilteredTreasurySet, setSelectedTreasuryItems, setIsFilteredAccountSet, setMessage, resetTreasuriesSet, getTreasuries, getAccounts, setStage1, resetMessage, getTransactionSummary, uploadFile, deleteFile, removeFile, } from "./DepositFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatBytes, formatDate, getIconNameFromMime, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../repository/Accountant/common_pb";
import { TableState } from "../../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../../repository/Accountant/account_pb";
import { AccountStatus, AuthorizedOperation, Operations, TreasuryStatus } from "../../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../../repository/Accountant/currency_pb";
import { DepositRequest, DepositResponse, GetTransactionSummary, GetTransactionSummaryRequest, GetTransactionSummaryResponse } from "../../../../../repository/Accountant/account_operation_pb";
import { GetTreasuriesRequest, GetTreasuriesResponse } from "../../../../../repository/Accountant/treasury_pb";
import { List } from "../../../../common/List/List";
import { Popup } from "../../../../common/Popup/Popup";
import { AccountsPage } from "../../../../pages/Accountant/Accounts/AccountsPage";
import { TreasuriesPage } from "../../../../pages/Accountant/Treasuries/TreasuriesPage";
import { AuthorizationForm } from "../../Accounts/AuthorizationForm";
import { AddFileRequest, AddFileResponse, DeleteFileRequest } from "../../../../../repository/Accountant/file_pb";


let req: DepositRequest;
let getAccountsReq: GetAccountsRequest;
let getrTreasuriesReq: GetTreasuriesRequest;
let fileUploadAction: any
let uploadReq: AddFileRequest
let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;


export const DepositForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState({
        type: DialogType.normal,
        title: '',
        subText: '',
    });
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const [currentAction, setCurrenctAction] = useState(0)

    const hiddenFileInputRef = useRef<any>(null);

    // 2) Function to trigger the click on the hidden input
    const handleShowFileDialog = () => {
        if (hiddenFileInputRef.current) {
            hiddenFileInputRef.current.click();
        }
    };
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [isDraggable],
    );

    const state: {
        isLoading: boolean,
        attachments: any[],
        message: ApiMessage | undefined, stage1: any, accounts: TableState, treasuries: TableState, confirmation: any[]
    } = useAppSelector((state) => {

        return {
            isLoading: state.depositForm.isLoading, message: state.depositForm.message,
            stage1: state.depositForm.stage1,
            accounts: state.depositForm.accounts,
            treasuries: state.depositForm.treasuries,
            confirmation: state.depositForm.confirmation,
            attachments: state.depositForm.attachments,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new DepositRequest();
        getAccountsReq = new GetAccountsRequest();
        getrTreasuriesReq = new GetTreasuriesRequest();
        uploadReq = new AddFileRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                getAccountsReq.setOrganization(wrapper)
                getrTreasuriesReq.setOrganization(wrapper)
                uploadReq.setOrganization(wrapper);
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);

        const statt = new Int32Value();
        statt.setValue(TreasuryStatus.ACTIVE);

        getAccountsReq.setNumofresults(state.accounts.numberOfResults)
        getAccountsReq.setOrder(state.accounts.isDescending)
        getAccountsReq.setStatus(stat)

        getrTreasuriesReq.setNumofresults(state.treasuries.numberOfResults)
        getrTreasuriesReq.setOrder(state.treasuries.isDescending)
        getrTreasuriesReq.setStatus(statt)


        if (props.renderObject && props.renderObject?.account) {

            dispatch(setSelectedAccountItems(([props.renderObject?.account])))
            const c = new StringValue();
            c.setValue(props.renderObject?.account?.currencyId)
            getrTreasuriesReq.setCurrency(c)
        }

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])


    useEffect(() => {
        if (state.confirmation.length > 0) {
            if (state.confirmation.at(0).creditorfeeValue != undefined && state.confirmation.at(0).creditorfeeValue != 0) {
                const formattedCurrency = currencyFormatter(Number(state.confirmation.at(0).amount), { significantDigits: state.confirmation.at(0)?.currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                const formattedFeeCurrency = currencyFormatter(Number(state.confirmation.at(0).creditorfeeValue), { significantDigits: state.confirmation.at(0).currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    subText: t("depositConfirmWithFee", { feeAmount: formattedFeeCurrency, amount: formattedCurrency, account: state.accounts.selected.at(0).name ?? "", treasury: state.treasuries.selected.at(0).name }),
                })
            } else {
                const formattedCurrency = currencyFormatter(Number(state.confirmation.at(0).amount), { significantDigits: state.accounts.selected.at(0)?.currencyDecimalPlaces ?? 9, symbol: state.accounts.selected.at(0)?.currencySymbol })
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    subText: t("depositConfirm", { amount: formattedCurrency, account: state.accounts.selected.at(0).name ?? "", treasury: state.treasuries.selected.at(0).name }),
                })
            }
            toggleHideDialog()
        }
    }, [state.confirmation])

    const treasurySuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('treasuries'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.treasuries.hasMore && !state.treasuries.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.treasuries.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedTreasuryItems([state.treasuries.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.treasuries.items.length > 0 && !state.treasuries.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.treasuries.items.at(state.treasuries.items.length - 1).internalId);
                getrTreasuriesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getTreasuries({ body: getrTreasuriesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.treasuries.items.length > 0 ? state.treasuries.items.map(e => { return { item: { name: e.name, key: e.internalId } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.treasuries.items.length == 0 ? state.treasuries.isFetching : false,
        isSearching: state.treasuries.items.length > 0 ? state.treasuries.isFetching : false,


    };
    const onTreasuriesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetTreasuriesSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getrTreasuriesReq.setSearch(wrapper)
        getrTreasuriesReq.setNextto(undefined)
        if (state.accounts.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.accounts.selected.at(0).currencyId);
            getrTreasuriesReq.setCurrency(r);
        }
        dispatch(setIsFilteredTreasurySet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getTreasuries({ body: getrTreasuriesReq, headers: getHeaders() }))
        return [];

    }


    const accountsSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accounts.hasMore && !state.accounts.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accounts.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                getAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.accounts.items.length == 0 ? state.accounts.isFetching : false,
        isSearching: state.accounts.items.length > 0 ? state.accounts.isFetching : false,


    };


    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getAccountsReq.setSearch(wrapper)
        getAccountsReq.setNextto(undefined)

        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
        return [];

    }





    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("treasuries")} onDismiss={() => { setCurrenctAction(0) }} >

                <TreasuriesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedTreasuryItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>



            <Formik
                enableReinitialize={true}
                initialValues={{

                    account: undefined,
                    treasury: undefined,
                    amount: "",
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],

                    message: "",
                    advancedOptions: false,
                    description: ""


                }}

                validationSchema={Yup.object({
                    amount: inputs.amount,
                    extraFields: inputs.extraFields,
                    message: inputs.message,
                    description: inputs.message,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let account = state.accounts.selected.at(0);
                        let treasury = state.treasuries.selected.at(0);

                        let amount = "";
                        let formattedCurrency = "";
                        if (values.amount) {
                            let r = Number(Number(values.amount?.replace(/,/g, '')).toFixed(account?.currencyDecimalPlaces ?? 9))
                            if (r <= 0) {
                                actions.setFieldValue("amount", "")
                                return;
                            }
                            formattedCurrency = currencyFormatter(r, { significantDigits: account?.currencyDecimalPlaces ?? 9, symbol: account?.currencySymbol })
                            amount = r + ""
                        }
                        req.setAccount(account.internalId);
                        req.setTreasury(treasury.internalId)
                        req.setAmount(amount)

                        const wrapperdesc = new StringValue();
                        wrapperdesc.setValue(values?.description);
                        req.setDescription(wrapperdesc)

                        if (values.advancedOptions) {
                            const wrapper = new StringValue();
                            wrapper.setValue(values.advancedOptions ? values.message : "");
                            req.setMessage(wrapper)

                            const extraField: ExtraField[] = [];
                            const count: Number = 1;
                            state.attachments.forEach((e: any) => {
                                const r = new ExtraField();
                                r.setKey(e.fileId);
                                extraField.push(r)
                            })
                            req.setAttachmentsList(extraField)

                        }
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: DepositResponse.AsObject) => {
                                dispatch(setMessage({ body: t("depositSuccessDesc", { amount: formattedCurrency, account: account?.name ?? "", accountId: account?.internalId ?? "", treasury: treasury?.name ?? "", treasuryId: treasury?.internalId ?? "" }), type: 1, title: t("depositSuccessTitle", { transaction: res?.success?.transactionid ?? "", transactionId: res?.success?.transactioninternalid ?? "" }), data: undefined }))
                                dispatch(setSelectedTreasuryItems([]))
                                actions.resetForm();
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res?.success?.transactionid,
                                            internalId: res?.success?.transactioninternalid,
                                            amount: amount,
                                            account: account.internalId,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            extraFields: values.extraFields

                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}

            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()

                        let a = Number(formkikProps.values.amount?.replace(/,/g, ''))
                        if (isNaN(a) || !formkikProps.isValid || a == 0) {
                            return;
                        }
                        dispatch(resetMessage())
                        let account = state.accounts.selected.at(0);
                        let treasury = state.treasuries.selected.at(0);

                        /* req.setAuthcode(undefined)
                         if (account.isAuthorizationRequired) {
                             setCurrenctAction(13);
                             return;
                         }*/

                        var sumary = new GetTransactionSummaryRequest();
                        const set: GetTransactionSummary[] = [];
                        const trans = new GetTransactionSummary();
                        trans.setAmount(formkikProps.values.amount?.replace(/,/g, ''))
                        trans.setCreditor(account.internalId)
                        trans.setDebtor(treasury.internalId)
                        trans.setOperation(Operations.DEPOSIT)
                        set.push(trans)
                        sumary.setTransactionsList(set)
                        promise?.abort();
                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders() }))

                    }} >

                        <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                            <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={
                                {
                                    account: state.accounts.selected.at(0),
                                    operation: AuthorizedOperation.DEPOSIT,
                                    parameters: [{ key: "Amount", value: formkikProps.values.amount?.replace(/,/g, ''), viewValue: state.accounts.selected.at(0)?.currencySymbol }],
                                }

                            } onSuccess={(e) => {
                                if (req && e.authCode) {
                                    if (currentAction == 13) {
                                        var r = new StringValue();
                                        r.setValue(e.authCode)
                                        req.setAuthcode(r)
                                        setCurrenctAction(0)

                                        let account = state.accounts.selected.at(0);
                                        let treasury = state.treasuries.selected.at(0);

                                        var sumary = new GetTransactionSummaryRequest();
                                        const set: GetTransactionSummary[] = [];
                                        const trans = new GetTransactionSummary();
                                        trans.setAmount(formkikProps.values.amount?.replace(/,/g, ''))
                                        trans.setCreditor(account.internalId)
                                        trans.setDebtor(treasury.internalId)
                                        trans.setOperation(Operations.DEPOSIT)
                                        set.push(trans)
                                        sumary.setTransactionsList(set)
                                        promise?.abort();
                                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders() }))

                                    }
                                } else {
                                    setCurrenctAction(0)
                                }
                            }} onCancel={() => { setCurrenctAction(0) }} />
                        </Popup>
                        <Dialog
                            hidden={hideDialog}
                            onDismiss={toggleHideDialog}
                            dialogContentProps={showConfirmation}
                            modalProps={modalProps}
                        >
                            <DialogFooter>
                                <PrimaryButton autoFocus onClick={(e: any) => { formkikProps.handleSubmit(e); toggleHideDialog() }} text={t("deposit")} type="submit" />
                                <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
                            </DialogFooter>
                        </Dialog>
                        <Stack tokens={{  childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("account")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                    isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                    moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                    suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                            getAccountsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("account"), required: true }}

                                    disabled={state.isLoading || props.renderObject?.account ? true : false}
                                    selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountsSet())
                                        getAccountsReq.setSearch(undefined)
                                        getAccountsReq.setNextto(undefined)
                                        dispatch(setIsFilteredAccountSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))

                                    }}
                                    onFilterChanged={onAccountsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100);

                                            }
                                        }]}
                                />

                            </Stack> : undefined}

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("treasury")}</Label>
                                <List
                                    inputProps={{ placeholder: t("treasury"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('treasuries')}
                                    isLoading={state.treasuries.items.length == 0 ? state.treasuries.isFetching : false}
                                    isSearching={state.treasuries.items.length > 0 ? state.treasuries.isFetching : false}
                                    moreSuggestionsAvailable={state.treasuries.hasMore && !state.treasuries.isFetching}
                                    suggestions={state.treasuries.items.length > 0 ? state.treasuries.items.map(e => { return { item: { name: e.name, key: e.internalId } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.treasuries.items.length > 0 && !state.treasuries.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.treasuries.items.at(state.treasuries.items.length - 1).internalId);
                                            getrTreasuriesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getTreasuries({ body: getrTreasuriesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.treasuries.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedTreasuryItems([state.treasuries.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.treasuries.selected.length > 0 ? state.treasuries.selected.map(e => { return { name: e.name, key: e.internalId } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedTreasuryItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetTreasuriesSet())
                                        getrTreasuriesReq.setSearch(undefined)
                                        getrTreasuriesReq.setNextto(undefined)
                                        if (state.accounts.selected.length > 0) {
                                            let r = new StringValue();
                                            r.setValue(state.accounts.selected.at(0).currencyId);
                                            getrTreasuriesReq.setCurrency(r);
                                        }
                                        dispatch(setIsFilteredTreasurySet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getTreasuries({ body: getrTreasuriesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onTreasuriesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}
                            {props.type == FormType.ADD ?
                                <Field name="amount" label={t("amount")} placeholder={t("amount")}
                                    component={InputField} disabled={state.isLoading} maxLength={15}
                                    onBlur={(event: any) => {
                                        if (isNaN(Number(formkikProps.values.amount?.replace(/,/g, '')))) {
                                            formkikProps.handleBlur(event);
                                            return;
                                        }
                                        let account = state.accounts.selected.at(0);
                                        if (account)
                                            formkikProps.setFieldValue("amount", Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(account?.currencyDecimalPlaces ?? 9))
                                        else
                                            formkikProps.setFieldValue("amount", Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(9))
                                        formkikProps.handleBlur(event);
                                    }}
                                    required suffix={state.accounts?.selected?.at(0)?.currencyCode} />


                                : undefined}

                            <Field name="description" label={
                                <div>
                                    {t("description") + ' '}
                                    <TooltipHost content={t("descriptionDesc")}>
                                        <Icon iconName="Info" aria-label={t("description")} />
                                    </TooltipHost>
                                </div>
                            } placeholder={t("description")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={140} />


                            <Toggle

                                label={t("advancedOptions")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.advancedOptions}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('advancedOptions', checked);
                                }
                                }
                            />

                            {formkikProps.values.advancedOptions ?
                                <Field name="message" label={
                                    <div>
                                        {t("message") + ' '}
                                        <TooltipHost content={t("messageDesc")}>
                                            <Icon iconName="Info" aria-label={t("message")} />
                                        </TooltipHost>
                                    </div>
                                } placeholder={t("message")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={140} />
                                : undefined}


                            {formkikProps.values.advancedOptions ? <Stack.Item>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("attachments") + ' '}
                                        <TooltipHost content={t("attachmentHint")}>
                                            <Icon iconName="Info" aria-label={t("attachments")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <FieldArray
                                    name="attachments"
                                    validateOnChange={false}

                                >
                                    {arrayHelpers => {
                                        let r = [] as React.ReactNode[];
                                        if (state.attachments && state.attachments.length > 0) {

                                            r.push(state.attachments.map((row, rowIndex) => {

                                                return (<Stack
                                                    horizontal
                                                    verticalAlign="center"
                                                    styles={{ root: { padding: 8, border: '1px solid #ccc', borderRadius: 4, maxWidth: 300 } }}
                                                    tokens={{ childrenGap: 8 }}
                                                >

                                                    <Icon iconName={getIconNameFromMime(row.mime)} styles={{ root: { fontSize: 24 } }} />

                                                    <Stack.Item grow>
                                                        <Stack>
                                                            <Text variant="mediumPlus" styles={{
                                                                root: {
                                                                    fontWeight: 600,
                                                                    // Truncation properties
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: 150,
                                                                    display: 'inline-block',
                                                                }
                                                            }}>
                                                                {row.originalName}
                                                            </Text>
                                                            <Text variant="small">{row.size}</Text>
                                                        </Stack>
                                                    </Stack.Item>

                                                    {row.isLoading ? <Spinner size={SpinnerSize.large} /> : <IconButton
                                                        iconProps={{ iconName: 'Cancel' }}
                                                        title={t("cancel")}
                                                        ariaLabel={t("cancel")}
                                                        onClick={() => {
                                                            if (row.isLoading) {
                                                                if (fileUploadAction)
                                                                    fileUploadAction.abort();
                                                                dispatch(removeFile(row.id))
                                                            } else {
                                                                let r = new DeleteFileRequest();
                                                                r.setId(row.fileId)
                                                                dispatch(deleteFile({ body: r, headers: getHeaders(), metadata: { id: row.id } }))
                                                            }
                                                        }}
                                                        styles={{ root: { marginLeft: 4 } }}
                                                    />}
                                                </Stack>

                                                )
                                            })
                                            )

                                        }
                                        if (state.attachments.length < 3) {
                                            const ALLOWED_EXTENSIONS = [
                                                '.jpg',
                                                '.jpeg',
                                                '.png',
                                                '.pdf',
                                                '.txt',
                                                '.doc',
                                                '.docx',
                                                '.xls',
                                                '.xlsx',
                                                '.heif',
                                                '.heic',
                                            ];

                                            r.push(<IconButton disabled={state.isLoading}
                                                key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {

                                                    handleShowFileDialog();

                                                }} >

                                                <input
                                                    type="file"
                                                    ref={hiddenFileInputRef}
                                                    style={{ display: "none" }}
                                                    accept={ALLOWED_EXTENSIONS.join(',')}
                                                    onChange={(e: any) => {
                                                        if (e.target.files && e.target.files.length > 0) {
                                                            // For example, push a new item with these files

                                                            //arrayHelpers.push({ key: (formkikProps.values.attachments.length + 1) + "", label: undefined, type: 1, value: event.target.files, autoFocus: false })

                                                            const [file] = e.target.files;
                                                            const reader = new FileReader();
                                                            reader.readAsArrayBuffer(file);
                                                            if (file.size > 8000000) {
                                                                //alert("File size must not exceed 5 MB.");
                                                                // Optional: reset the input if you want to clear it
                                                                //e.target.value = null; 
                                                                formkikProps.setFieldError("attachments", "eRROR")
                                                                return;
                                                            }
                                                            reader.onloadend = (evt) => {
                                                                if (evt?.target?.readyState === FileReader.DONE) {
                                                                    const arrayBuffer = evt.target.result;
                                                                    let array = new Uint8Array(arrayBuffer as ArrayBuffer);
                                                                    uploadReq.setFile(array)
                                                                    const fullName = file.name?.split(".")[0] ?? "file";

                                                                    uploadReq.setName(fullName)
                                                                    const mimeType = file.type || ""; // If the type isn't recognized, it may be ""
                                                                    uploadReq.setMime(mimeType)

                                                                    fileUploadAction = dispatch(uploadFile({ body: uploadReq, headers: getHeaders(), metadata: { mime: mimeType, size: formatBytes(file.size ?? 0), originalName: uploadReq.getName(), id: (new Date()).getTime() } }))


                                                                }
                                                            }

                                                        }
                                                        //handleFilesChange(event.target.files, arrayHelpers)

                                                    }

                                                    }
                                                // If you do NOT want multiple, remove the `multiple` attribute above
                                                />

                                            </IconButton>)
                                        }
                                        return r;
                                    }
                                    }

                                </FieldArray>
                            </Stack.Item>
                                : undefined}
                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("deposit") : t("edit")}
                                type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

