import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { TreasuryOperationEndpointClient } from '../../../../../../../repository/Accountant/Treasury_operationServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../../../app/Api';
import { downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../../../../app/Helpers';
import { DynamicField } from '../../../../../../common/DynamicFields/DynamicFields';
import { TableState } from '../../../../../../common/Table/TableSate';
import { ExportTransactionRequest, GetTransactionRequest, GetTransactionResponse, ExportTransactionResponse } from '../../../../../../../repository/Accountant/account_operation_pb';
import { AccountOperationEndpointClient } from '../../../../../../../repository/Accountant/Account_operationServiceClientPb';


export interface AccountStatementDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean,

}

const initialState: AccountStatementDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false,


}

const client = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);


export const get = createAsyncThunk<GetTransactionResponse.AsObject, APIRequest<GetTransactionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/statementDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTransaction(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const exportItem = createAsyncThunk<APIResponse<ExportTransactionResponse.AsObject>, APIRequest<ExportTransactionRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/statementDetails/export',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.exportTransaction(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { exportType: req.body.getExporttype() } };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const accountStatementDetailsPageSlice = createSlice({
    name: 'pages/accountant/accountDetails/statementDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;

        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;

        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        },




    },
    extraReducers: (builder) => {



        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                 let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    amount: payload.success?.amount?.value ?? undefined,
                    currencyId: payload.success?.currency?.id?.value ?? "",
                    currencyName: payload.success?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.currency?.symbol?.value ?? "",
                    operation: payload.success?.operation?.value ?? undefined,
                    referenceTransactionId: payload.success?.referencetransaction?.id?.value ?? undefined,
                    referenceTransactionInternalId: payload.success?.referencetransaction?.internalid?.value ?? "",
                    debtorId: payload.success?.debtor?.id?.value ?? undefined,
                    debtorInternalId: payload.success?.debtor?.internalid?.value ?? "",
                    debtorName: payload.success?.debtor?.name?.value ?? "",
                    debtorBalanceBefore: payload.success?.debtor?.balancebefore?.value ?? undefined,
                    debtorBalanceAfter: payload.success?.debtor?.balanceafter?.value ?? undefined,
                    debtorDescription: payload.success?.debtor?.description?.value ?? undefined,
                    debtorIsTreasury: payload.success?.debtor?.istreasury?.value ?? undefined,
                    creditorId: payload.success?.creditor?.id?.value ?? undefined,
                    creditorInternalId: payload.success?.creditor?.internalid?.value ?? "",
                    creditorName: payload.success?.creditor?.name?.value ?? "",
                    message: payload.success?.creditor?.receivedmessage?.value ?? payload.success?.debtor?.receivedmessage?.value ?? "",
                    creditorIsTreasury: payload.success?.creditor?.istreasury?.value ?? undefined,
                    creditorBalanceBefore: payload.success?.creditor?.balancebefore?.value ?? undefined,
                    creditorBalanceAfter: payload.success?.creditor?.balanceafter?.value ?? undefined,
                    creditorDescription: payload.success?.creditor?.description?.value ?? undefined,
                    creditorFeeTransactionId: payload.success?.creditor?.feetransaction?.id?.value ?? undefined,
                    creditorFeeTransactionInternalId: payload.success?.creditor?.feetransaction?.internalid?.value ?? undefined,
                    debtorFeeTransactionId: payload.success?.debtor?.feetransaction?.id?.value ?? undefined,
                    debtorFeeTransactionInternalId: payload.success?.debtor?.feetransaction?.internalid?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[],
                    attachments: [] as DynamicField[],

                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }
                if (payload?.success?.attachmentsList) {
                    let count = 0
                    payload?.success?.attachmentsList?.forEach(element => {
                        r.attachments.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }


                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(exportItem.fulfilled, (state, { payload , }) => {
            state.isChangeStateLoading = false;
            if (payload.response.success?.statement && payload.metadata?.exportType == 3) {
       
                downloadFile(payload.response.success?.statement+ "", payload.response.success.name?.value ?? "order" + getFileExtension(payload.metadata?.exportType))

            }

        })
        builder.addCase(exportItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(exportItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = accountStatementDetailsPageSlice.actions

export default accountStatementDetailsPageSlice.reducer