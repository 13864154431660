import React from "react";
import { DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { getBalance, dismissMessage, getCurrencies, reset, resetCurrencies, setIsFilteredCurrenciesSet, setSelectedCurrencyItems } from "./AccountTypeBalanceFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";
import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatDate, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../repository/Accountant/common_pb";
import { TableState } from "../../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../../repository/Accountant/account_pb";
import { GetCurrenciesRequest } from "../../../../../repository/Accountant/currency_pb";
import { AppendTreasuryCurrencyRequest } from "../../../../../repository/Accountant/treasury_pb";
import { List } from "../../../../common/List/List";
import { CurrenciesPage } from "../../../../pages/Accountant/Currencies/CurrenciesPage";
import { Popup } from "../../../../common/Popup/Popup";
import { GetAccountTypeBalanceRequest, GetAccountTypeBalanceResponse } from "../../../../../repository/Accountant/account_type_pb";
import { TextView } from "../../../../common/DataView/TextView"


let req: GetAccountTypeBalanceRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;
let currenciesReq: GetCurrenciesRequest;

export const AccountTypeBalanceForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)
    const [balance, setBalance] = useState<any>(undefined);
    const state: { isLoading: boolean, message: ApiMessage | undefined, currencies: TableState, stage1: any } = useAppSelector((state) => {

        return {
            isLoading: state.accountTypeBalance.isLoading, message: state.accountTypeBalance.message, stage1: state.accountTypeBalance.stage1
            ,
            currencies: state.accountTypeBalance.currencies,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new GetAccountTypeBalanceRequest();

        currenciesReq = new GetCurrenciesRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                currenciesReq.setOrganization(wrapper)
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
        // currenciesReq.setIsavailable(boolv)
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }


    return (
        balance ?
            <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
    
                <TextView styles={{ root: { fontWeight: "bold" } }} label={t("freeBalance")} value={balance?.freebalance?.value ? currencyFormatter(balance?.freebalance?.value, { significantDigits: balance?.currency?.decimalplaces?.value ?? 9, symbol: balance?.currency?.symbol?.value }) : ""} />
                <TextView styles={{ root: { fontWeight: "bold" } }} label={t("reservedBalance")} value={balance?.reservedbalance?.value ? currencyFormatter(balance?.reservedbalance?.value, { significantDigits: balance?.currency?.decimalplaces?.value ?? 9, symbol: balance?.currency?.symbol?.value }) : ""} />
                <TextView styles={{ root: { fontWeight: "bold" } }} label={t("totalBalance")} value={balance?.totalbalance?.value ? currencyFormatter(balance?.totalbalance?.value, { significantDigits: balance?.currency?.decimalplaces?.value ?? 9, symbol: balance?.currency?.symbol?.value }) : ""} />

            </Stack>
            : <>
                <Popup isOpen={(currentAction == 100)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                    <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                        dispatch(setSelectedCurrencyItems([e]))
                        setCurrenctAction(0)
                    }} onCancel={() => { setCurrenctAction(0) }} />

                </Popup>
                <Formik
                    enableReinitialize={true}
                    initialValues={{

                        currency: undefined,
                        isActive: (props.renderObject) ? props.renderObject?.isActive ?? true : true,

                    }}

                    validationSchema={Yup.object({




                    })}

                    onSubmit={(values, actions) => {
                        if (props.type == FormType.ADD) {
                            let currencies = state.currencies.selected.at(0);

                            req.setCurrency(currencies.id);
                            req.setId(props.renderObject?.id)

                            promise = dispatch(getBalance({ body: req, headers: getHeaders() }))
                            promise.unwrap()
                                .then((res: GetAccountTypeBalanceResponse.AsObject) => {
                                    if (res) {
                                        if (props?.onSuccess) {
                                            setBalance(res.success)
                                        }
                                    }
                                    actions.setSubmitting(false)
                                })
                                .catch((error: ApiMessage) => {
                                    actions.setSubmitting(false)
                                })
                        } else {

                        }
                    }}

                >
                    {formkikProps => (
                        <Form>
                            <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                                {state.message != undefined ? (state.message.data != 401) ? <Message
                                    body={state.message.body}
                                    title={state.message.title}
                                    data={state.message.data}
                                    onDismiss={() => { dispatch(dismissMessage()) }}
                                    type={state.message.type}
                                /> :
                                    <SessionExpiredDialog />
                                    : null
                                }

                                {props.type == FormType.ADD ? <Stack>
                                    <Label required disabled={state.isLoading}>{t("currency")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("currency"), required: true }}
                                        disabled={state.isLoading}
                                        suggestionsHeaderText={t('currencies')}
                                        isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                        isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                        moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                        suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []} onGetMoreResults={() => {
                                            if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                                currenciesReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.currencies.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                            }
                                        }}
                                        isPeoplePicker={true}
                                        selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedCurrencyItems([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetCurrencies())
                                            currenciesReq.setSearch(undefined)
                                            currenciesReq.setNextto(undefined)
                                            dispatch(setIsFilteredCurrenciesSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onCurrenciesFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(100)

                                                }
                                            }]}
                                    />
                                </Stack> : undefined}


                            </Stack>
                            <DialogFooter>
                                <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("getBalance") : t("getBalance")} type="submit" >

                                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                                </PrimaryButton>
                                <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                    if (props.onCancel) {
                                        formkikProps.resetForm()
                                        props.onCancel()
                                    }
                                }} />
                            </DialogFooter>

                        </Form>
                    )}
                </Formik>
            </>
    );

}

