/**
 * @fileoverview gRPC-Web generated client stub for file_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as file_pb from './file_pb';


export class FileEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/file_endpoint.FileEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    file_pb.AddFileRequest,
    file_pb.AddFileResponse,
    (request: file_pb.AddFileRequest) => {
      return request.serializeBinary();
    },
    file_pb.AddFileResponse.deserializeBinary
  );

  add(
    request: file_pb.AddFileRequest,
    metadata: grpcWeb.Metadata | null): Promise<file_pb.AddFileResponse>;

  add(
    request: file_pb.AddFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: file_pb.AddFileResponse) => void): grpcWeb.ClientReadableStream<file_pb.AddFileResponse>;

  add(
    request: file_pb.AddFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: file_pb.AddFileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/file_endpoint.FileEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/file_endpoint.FileEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/file_endpoint.FileEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    file_pb.DeleteFileRequest,
    file_pb.DeleteFileResponse,
    (request: file_pb.DeleteFileRequest) => {
      return request.serializeBinary();
    },
    file_pb.DeleteFileResponse.deserializeBinary
  );

  delete(
    request: file_pb.DeleteFileRequest,
    metadata: grpcWeb.Metadata | null): Promise<file_pb.DeleteFileResponse>;

  delete(
    request: file_pb.DeleteFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: file_pb.DeleteFileResponse) => void): grpcWeb.ClientReadableStream<file_pb.DeleteFileResponse>;

  delete(
    request: file_pb.DeleteFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: file_pb.DeleteFileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/file_endpoint.FileEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/file_endpoint.FileEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

}

