import moment from "moment";
import { ReactInstance } from "react";
import { getSession } from "./Api";
import { AuthorizedOperation, Operations, OrderAction, OrderStatus, OrderType } from "./Enums";
import i18n from "./i18n";


export const timestampToDate = (seconds: number | undefined, nanos: number | undefined, asUTC = false): Date | undefined => {

    if (seconds) {

        const a = new Date(seconds * 1000);
        if (nanos)
            a.setMilliseconds((nanos) / 1e6)

        if (asUTC) {
            let localDate = new Date(a.getTime() + (new Date()).getTimezoneOffset() * 60000); // Adjust by offset
            return localDate;
        }
        return a;
    }

}


export const formatDate = (date: Date | undefined, format = 'YYYY-MM-DD HH:mm:ss'): string => {


    return moment(date).format(format);

}

export const phoneNumberFormatter = (PhoneNumber: string): string | undefined => {
    PhoneNumber = PhoneNumber.trim();
    PhoneNumber = PhoneNumber.replace('\u0660', '0')
        .replace('\u0661', '1')
        .replace('\u0662', '2')
        .replace('\u0663', '3')
        .replace('\u0664', '4')
        .replace('\u0665', '5')
        .replace('\u0666', '6')
        .replace('\u0667', '7')
        .replace('\u0668', '8')
        .replace('\u0669', '9'); // ٠٩١٤٢٦٧٣١٥ cases
    if (PhoneNumber.length >= 8) {
        PhoneNumber = PhoneNumber.trim().replace(" ", "").replace("+", "").replace("-", ""); // Remove spaces, +, -
        if (PhoneNumber.length < 4)
            return undefined;
        let isAllDigits = /^\d+$/.test(PhoneNumber);
        if (!isAllDigits)
            return undefined;
        if (PhoneNumber.length > 15)
            return undefined;

        if (PhoneNumber.startsWith("00")) {
            PhoneNumber = PhoneNumber.substring(2); // 00218912129959 => 218912129959
        }
        else if (PhoneNumber.startsWith("09")) // Local case
        {
            PhoneNumber = "218" + PhoneNumber.substring(1); // 0912129599 => 218912129959
        }
        return PhoneNumber;
    }
    return undefined;

}

export const getCurrentLanguage = (): string => {

    var lang = i18n.language || localStorage.getItem("lang") || 'ar'
    return lang;

}

export const normalizeKey = (key: string) => key as unknown as TemplateStringsArray;

export const currencyFormatter = (value: number, options: any) => {


    options = {
        ...{
            thousandsSeparator: ',',
            decimalSeparator: '.',
        }, ...options
    }
    let num = Number(value);
    if (isNaN(num)) {
        return ''
    }
    let minus = ""
    if (num < 0) {
        num = num * -1;
        minus = "-"
    }
    let v = options.significantDigits == undefined ? num + "" : num.toFixed(options.significantDigits)


    const [currency, decimal] = v.split('.')
    if (options.symbol == undefined) {
        if (options.significantDigits == 0) {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${minus}`
        } else {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${options.decimalSeparator}${decimal ?? "0"}${minus}`
        }


    }
    if (isRTL(options.symbol)) {
        if (options.significantDigits == 0) {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${minus} ${options.symbol}`
        } else {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${options.decimalSeparator}${decimal ?? "0"}${minus} ${options.symbol}`
        }

    }
    if (options.significantDigits == 0) {
        return `${options.symbol}${minus}${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}`
    } else {
        return `${options.symbol}${minus}${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}${options.decimalSeparator}${decimal ?? "0"}`
    }


}

export const isRTL = (text: string): boolean => {

    let regexp = new RegExp('[\u0600-\u06FF]+')
    return regexp.test(text);

}


export const printComponent = (html?: any, isTable: boolean = false): any => {

    const current = getSession();
    if (!current) {
        return html;
    }
    const PrintElem = document.createElement('div');
    PrintElem.className = isTable ? "table_printing_content" : "printing_content"
    const header =
        `<b>${((current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? "")).trim() + " (" + (current.username?.value ?? "") + " - " + (current.organization?.name?.value ?? "") + ")"}</b>` +
        `<br><b>${formatDate(new Date())}</b>` +
        `<br><b>${window.location.href}</b>`
    PrintElem.innerHTML = header;
    PrintElem.appendChild(html);
    return PrintElem
}

export const printOrder = (order: any, t: any): any => {
    const html = document.createElement('html');
    const current = getSession();
    if (!current) {
        return html;
    }
    const style = document.createElement('style');
    style.innerHTML = `*{font-size:12px;font-family:'Times New Roman';direction:${getCurrentLanguage() == "ar" ? "rtl" : "ltr"};word-wrap:break-word;overflow-wrap:break-word}.centered{text-align:center;align-content:center}@media print{.hidden-print,.hidden-print *{display:none!important}}.key {white-space:nowrap;}@page{margin:0;word-wrap:break-word;overflow-wrap:break-word}`
    html.appendChild(style);

    const PrintElem = document.createElement('body');

    const div = document.createElement('div');
    div.innerHTML = `<p class="centered">${current.organization?.name?.value}<br><br>* * * * * * * * * * * * * *</p><table> <tbody> <tr> <td class="key">${t("id")}:</td><td>${order?.id}</td></tr><tr> <td class="key">${t("pair")}:</td><td>${order?.currencyPairName}</td></tr><tr> <td class="key">${t("orderType")}:</td><td>${(order?.orderType == OrderType.MARKET_ORDER ? t("marketOrder") : order?.orderType == OrderType.LIMIT_ORDER ? t("limitOrderInternal") : order?.orderType == OrderType.LIMIT_ORDER_External ? t("limitOrderExternal") : order?.orderType == OrderType.ALL_OR_NONE_ORDER ? t("AON") : "") + " - " + (order?.tradeAction == OrderAction.BUY ? t("buy") : t("sell"))}</td></tr><tr> <td class="key">${t("status")}:</td><td>${order?.status == OrderStatus.OPEN ? t("open") : order?.status == OrderStatus.FILLD ? t("filled") : order?.status == OrderStatus.NEW ? t("new") : order?.status == OrderStatus.CANCELED ? t("cancelled") : order?.status == OrderStatus.NEW ? t("new") : ""}</td></tr><tr> <td class="key">${t("amount")}:</td><td>${currencyFormatter(order?.itemQuantity, { significantDigits: order?.currencyPairBaseCurrencyDecimalPlaces ?? 9, symbol: order?.currencyPairBaseCurrencySymbol })}</td></tr><tr> <td class="key">${t("price")}:</td><td>${currencyFormatter(order?.itemPrice, { significantDigits: order?.currencyDecimalPlaces ?? 9, symbol: order?.currencySymbol })}</td></tr><tr> <td class="key">${t("total")}:</td><td>${currencyFormatter(order?.total, { significantDigits: order?.currencyDecimalPlaces ?? 9, symbol: order?.currencySymbol })}</td></tr><tr> <td class="key">${t("executedDate")}:</td><td>${order?.createdDate}</td></tr><tr> <td class="key">${t("executer")}:</td><td>${order?.addedBy == "" ? order?.addedById : order?.addedBy}</td></tr></tbody> </table><p class="centered"> * * * * * * * * * * * * * *</p><p class="centered">${t("printFooter")}<br>devpoint.ly</p>`
    PrintElem.appendChild(div);
    html.appendChild(PrintElem);
    return html

};



/**
 * printStatement
 * @param statement JSON string representing the array of "print items" from server
 * @param isCredit   (Retained from your original signature, if you need to customize logic)
 * @param t          Translation function, e.g. i18n (like you had)
 */


export const printStatement = (statement: string, ): any => {
    // 1) Parse the JSON string from the server
    let printItems: any[] = [];
    try {
      printItems = JSON.parse(statement);
    } catch (err) {
      console.error("Invalid JSON statement:", err);
      // If parsing fails, return an empty <html> element
      return document.createElement('html');
    }
  
    // 2) Create the skeleton HTML document
    const html = document.createElement('html');
  
    // Add some style (similar to your original)
    const style = document.createElement('style');
    style.innerHTML = `
      * {
        font-size: 12px;
        font-family: 'Times New Roman';
        direction: ${getCurrentLanguage() === 'ar' ? 'rtl' : 'ltr'};
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      .centered {
        text-align: center;
        align-content: center;
      }
      @media print {
        .hidden-print, .hidden-print * {
          display: none !important;
        }
      }
      .key {
        white-space: nowrap;
      }
      @page {
        margin: 0;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    `;
    html.appendChild(style);
  
    // Create <body> to hold receipt content
    const PrintElem = document.createElement('body');
    let currentAlign = getCurrentLanguage() === 'ar' ? 'right' : 'left';
    const div = document.createElement('div');
    // 3) Iterate over the array of print items, building HTML dynamically
    printItems.forEach((item) => {
      switch (item.t) {
        /**
         * t=1 => Text line
         *   "text" => the text to display
         *   "size" => optional font size
         */
        case 1: {
          const text = item.text || "";
          const size = item.size || 12; // default 12px if not provided
  
          const p = document.createElement('p');
          p.style.margin = "5px 0";
          p.style.textAlign = currentAlign;
          p.style.fontSize = `${size}px`;
          p.innerText = text;  // or use innerHTML if text can contain HTML
          div.appendChild(p);
          break;
        }
  
        /**
         * t=2 => Alignment
         *   "align" => 0=left, 1=center, 2=right
         */
        case 2: {
          const align = item.align;
          switch (align) {
            case 0:
              currentAlign = 'left';
              break;
            case 1:
              currentAlign = 'center';
              break;
            case 2:
              currentAlign = 'right';
              break;
            default:
              currentAlign = 'left';
              break;
          }
          break;
        }
  
        /**
         * t=3 => Line breaks (wrap)
         *   "wrap" => number of blank lines
         */
        case 3: {
          const count = item.wrap || 1;
          for (let i = 0; i < count; i++) {
            div.appendChild(document.createElement('br'));
          }
          break;
        }
  
        /**
         * t=4 => Barcode/QR code
         *   "data" => data to encode
         *   "size" => optional size or other properties
         */
        case 4: {
          // For a quick example, we'll generate a QR code using a public API
          // For production, consider using a library like JsBarcode or a local QR library.
          const codeData = encodeURIComponent(item.data || "");
          const img = document.createElement('img');
          // Example: using QR code from a public API:
          img.src = `https://api.qrserver.com/v1/create-qr-code/?data=${codeData}&size=150x150`;
  
          // You might also want to style the image
          img.style.display = 'block';
          img.style.margin = '10px auto'; // center it
  
          div.appendChild(img);
          break;
        }
  
        /**
         * t=5 => Base64 Image
         *   "data" => base64-encoded string
         */
        case 5: {
          // If the server is sending the base64 image without the prefix,
          // we can assume "data:image/png;base64," + item.data
          if (item.data) {
            const img = document.createElement('img');
            img.src = `data:image/png;base64,${item.data}`;
            img.style.display = 'block';
            img.style.margin = '10px auto';
            img.style.maxWidth = '100%';
  
            div.appendChild(img);
          }
          break;
        }
  
        default:
          // Unknown type => do nothing or handle any custom logic
          break;
      }
    });
    PrintElem.appendChild(div);
    // 4) Append everything to the <html>
    html.appendChild(PrintElem);
  
    console.log(html.innerHTML)
    return html;
  };
  
/* 0: common, 1: treasury, 2:account, 3: treasury + common, 4: account + common  */
export const getOperations = (isTransactional?: boolean, type?: number, isInternalOrder?: boolean): { key: number, text: string, isTransactional: boolean, isInternalOrder?: boolean, type?: number }[] => {
    let set = [{ key: Operations.QUERY_BALANCE, text: "queryBalance", isTransactional: false, type: 0, isInternalOrder: false },
    { key: Operations.ISSUE_STATEMENT, text: "issueStatement", isTransactional: false, type: 0, isInternalOrder: false },
    { key: Operations.TRANSFER, text: "transfer", isTransactional: true, type: 2, isInternalOrder: false },
    { key: Operations.EXTERNAL_TRANSFER, text: "externalTransfer", isTransactional: true, type: 2, isInternalOrder: false },
    { key: Operations.SETTLEMENT, text: "settlement", isTransactional: true, type: 2, isInternalOrder: false },

    { key: Operations.DEPOSIT, text: "deposit", isTransactional: true, type: 0, isInternalOrder: false },
    { key: Operations.WITHDRAW, text: "withdraw", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.FEE_COLLECTION, text: "feeCollection", isTransactional: true, type: 2, isInternalOrder: true }
        , { key: Operations.RETURN_RESERVED_BALANCE, text: "returnReversedBalance", isTransactional: false, type: 2, isInternalOrder: true }
        , { key: Operations.TRADING_ORDER_SELL, text: "internalTradingOrderSell", isTransactional: true, type: 2, isInternalOrder: false }
        , { key: Operations.TRADING_ORDER_BUY, text: "internalTradingOrderBuy", isTransactional: true, type: 2, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_SELL_DEPOSIT, text: "externalTradingOrderSellDeposit", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_SELL_WITHDRAW, text: "externalTradingOrderSellWithdraw", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_BUY_DEPOSIT, text: "externalTradingOrderBuyDeposit", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_BUY_WITHDRAW, text: "externalTradingOrderBuyWithdraw", isTransactional: true, type: 0, isInternalOrder: false }

    ];

    if (isTransactional == undefined)
        set = set;
    else if (isTransactional)
        set = set.filter(e => e.isTransactional == true);
    else {
        set = set.filter(e => e.isTransactional == false);
    }

    if (type == undefined)
        set = set;
    else if (type == 3)
        set = set.filter(e => e.type == 1 || e.type == 0);
    else if (type == 4)
        set = set.filter(e => e.type == 2 || e.type == 0);
    else
        set = set.filter(e => e.type == type);

    if (isInternalOrder == undefined)
        set = set;
    else if (isInternalOrder)
        set = set.filter(e => e.isInternalOrder == true);
    else {
        set = set.filter(e => e.isInternalOrder == false);
    }

    return set;
}




export const getAccountAuthorizedPermissions = (): { key: number, text: string }[] => {
    let set = [{ key: AuthorizedOperation.QUERY_BALANCE, text: "queryBalance", },
    { key: AuthorizedOperation.ISSUE_STATEMENT, text: "issueStatement", },
    { key: AuthorizedOperation.TRANSFER, text: "transfer", },
    { key: AuthorizedOperation.EXTERNAL_TRANSFER, text: "externalTransfer", },
    { key: AuthorizedOperation.DEPOSIT, text: "deposit" },
    { key: AuthorizedOperation.WITHDRAW, text: "withdraw" },
    { key: AuthorizedOperation.TRIGGERED_TRANSFER, text: "accountLinking" },
    { key: AuthorizedOperation.RESERVE_BALANCE, text: "reserveAmount" },
    { key: AuthorizedOperation.FREE_BALANCE, text: "freeAmount" },
    { key: AuthorizedOperation.TRADING_ORDER_SELL, text: "tradingOrderSell", },
    { key: AuthorizedOperation.TRADING_ORDER_BUY, text: "tradingOrderBuy" }

    ];


    return set;
}

export const getAuthorizations = (): { key: number, text: string }[] => {
    let set = [{ key: AuthorizedOperation.QUERY_BALANCE, text: "queryBalance", },
    { key: AuthorizedOperation.ISSUE_STATEMENT, text: "issueStatement", },
    { key: AuthorizedOperation.TRANSFER, text: "transfer", },
    { key: AuthorizedOperation.EXTERNAL_TRANSFER, text: "externalTransfer", },
    { key: AuthorizedOperation.DEPOSIT, text: "deposit" },
    { key: AuthorizedOperation.WITHDRAW, text: "withdraw" },
    { key: AuthorizedOperation.TRIGGERED_TRANSFER, text: "accountLinking" },
    { key: AuthorizedOperation.RESERVE_BALANCE, text: "reserveAmount" },
    { key: AuthorizedOperation.FREE_BALANCE, text: "freeAmount" },
    { key: AuthorizedOperation.TRADING_ORDER_SELL, text: "tradingOrderSell", },
    { key: AuthorizedOperation.TRADING_ORDER_BUY, text: "tradingOrderBuy" },




    { key: AuthorizedOperation.UPDATE_AUTHORIZATION_REQUIREMENT, text: "authorizationStatus" },
    { key: AuthorizedOperation.TRANSFER_OWNERSHIP, text: "transferOwnership", },
    { key: AuthorizedOperation.ADD_ACCOUNT_AUTHORIZER, text: "addAccountAuthorization" },
    { key: AuthorizedOperation.DELETE_ACCOUNT_AUTHORIZER, text: "editAccountAuthorization" },
    { key: AuthorizedOperation.IDENTITY_SIGNATURE, text: "generateSignature" },

    ];


    return set;
}



export const getCurrentOrganization = (): any => {
    const i: string | null = localStorage.getItem("currentOrg")
    if (i) {
        let currentOrg = JSON.parse(i)
        if (currentOrg)
            return i;
        return undefined;
    }
    return undefined;

};

export const setCurrentOrganization = (e: any): any => {
    let currentOrg = JSON.stringify(e)
    if (currentOrg)
        localStorage.setItem('currentOrg', currentOrg)

};


export const base64ToArrayBuffer = (e: string) => {
    var binary_string = window.atob(e);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export const downloadFile = (base64Data: string, fileName: string) => {
    var data = new Blob([base64ToArrayBuffer(base64Data)]);
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();

}

export const formatBytes = (bytes: number): string => {
    if (bytes >= 1024 * 1024) {
        return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    } else if (bytes >= 1024) {
        return `${(bytes / 1024).toFixed(2)} KB`;
    } else {
        return `${bytes} Bytes`;
    }
}


export const getFileExtension = (type: number): string => {
    return type == 1 ? ".xlsx" : type == 2 ? ".csv" : type == 3 ? ".pdf" : ".txt";
}


/**
 * Rounds a timestamp down to the beginning of a specified interval.
 *
 * @param {number} timestamp - The input timestamp in milliseconds.
 * @param {number} bucketCode - The bucket code indicating the desired interval:
 *                              0: minute,
 *                              1: 15 minutes,
 *                              2: 30 minutes,
 *                              3: hour,
 *                              4: 4 hours,
 *                              5: day,
 *                              6: week (first day is Saturday),
 *                              7: month.
 * @returns {number} The timestamp (in milliseconds) corresponding to the beginning of the bucket.
 *
 * @throws {Error} If an unsupported bucket code is provided.
 */
export const roundTimestamp = (timestamp: any, bucketCode: string) => {
    const date = new Date(timestamp);
    // ["1S", "1", "15", "30", "60", "240", "1D", "1W", "1M"],
    switch (bucketCode) {
        case "1S": { // Beginning of minute
            date.setMilliseconds(0);
            break;
        }
        case "1": { // Beginning of minute
            date.setSeconds(0, 0);
            break;
        }
        case "15": { // Beginning of 15-minute period
            date.setSeconds(0, 0);
            const minutes = date.getMinutes();
            date.setMinutes(Math.floor(minutes / 15) * 15);
            break;
        }
        case "30": { // Beginning of 30-minute period
            date.setSeconds(0, 0);
            const minutes = date.getMinutes();
            date.setMinutes(Math.floor(minutes / 30) * 30);
            break;
        }
        case "60": { // Beginning of hour
            date.setMinutes(0, 0, 0);
            break;
        }
        case "240": { // Beginning of 4-hour block
            date.setMinutes(0, 0, 0);
            const hours = date.getHours();
            date.setHours(Math.floor(hours / 4) * 4);
            break;
        }
        case "1D": { // Beginning of day
            date.setHours(0, 0, 0, 0);
            break;
        }
        case "1W": {  // Beginning of week (with Saturday as the first day)
            // First, set to beginning of day.
            date.setHours(0, 0, 0, 0);
            // In JavaScript, getDay() returns 0 for Sunday, 1 for Monday, …, 6 for Saturday.
            // We want the week to start on Saturday.
            // If today is Saturday (6), we stay; if Sunday (0), subtract 1 day; if Monday (1), subtract 2 days; etc.
            const day = date.getDay();
            const diff = (day + 1) % 7;  // Saturday => (6+1)%7 = 0; Sunday => (0+1)%7 = 1; etc.
            date.setDate(date.getDate() - diff);
            break;
        }
        case "1M": { // Beginning of month
            date.setDate(1);
            date.setHours(0, 0, 0, 0);
            break;
        }
        default:
            throw new Error("Invalid bucket code. Must be an integer between 0 and 7.");
    }

    return date.getTime();
}

export const getIconNameFromMime = (mimeType: string): string => {
    const mimeToIconMap: Record<string, string> = {
        // Document types
        "application/pdf": "PDF",
        "application/msword": "WordDocument",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "WordDocument",
        "application/vnd.ms-excel": "ExcelDocument",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "ExcelDocument",
        "text/plain": "TextDocument",

        // Image types
        "image/jpeg": "FileImage",
        "image/jpg": "FileImage",
        "image/pjpeg": "FileImage",
        "image/x-png": "FileImage",
        "image/png": "FileImage",



        // Default or unknown types
        "default": "TextDocument",
    };

    return mimeToIconMap[mimeType] || mimeToIconMap["default"];
}
