import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL } from '../../../../../app/Api';
import { downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../../app/Helpers';
import { CurrencyPairEndpointClient } from '../../../../../repository/Trading/Currency_pairServiceClientPb';
import { OrderEndpointClient } from '../../../../../repository/Trading/OrderServiceClientPb';
import { CancelRequest, CancelResponse, ExportOrderRequest, ExportOrderResponse, GetOrderRequest, GetOrderResponse } from '../../../../../repository/Trading/order_pb';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { OrderStatus } from '../../../../../app/Enums';


export interface OrderDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: OrderDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new OrderEndpointClient(TRADING_API_URL, null, null);

export const get = createAsyncThunk<GetOrderResponse.AsObject, APIRequest<GetOrderRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/orderDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getOrder(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const cancelOrder = createAsyncThunk<APIResponse<CancelResponse.AsObject>, APIRequest<CancelRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/orderDetails/cancel',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.cancel(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const exportItem = createAsyncThunk<APIResponse<ExportOrderResponse.AsObject>, APIRequest<ExportOrderRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/trading/orderDetails/export',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.exportOrder(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { exportType: req.body.getExporttype() } };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)
export const orderDetailsPageSlice = createSlice({
    name: 'pages/trading/orderDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
       
        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let val = payload.success;
                let rc = {
                    id: val?.id,
                    fromAccountInternalId: val?.fromaccount?.internalid?.value ?? "",
                    fromAccountName: val?.fromaccount?.name?.value ?? "",
                    fromAccountId: val?.fromaccount?.id?.value ?? "",
                    currencyId: val?.currency?.id?.value ?? "",
                    currencyName: val?.currency?.name?.value ?? "",
                    currencyCode: val?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: val?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: val?.currency?.form?.value ?? undefined,
                    currencySymbol: val?.currency?.symbol?.value ?? "",
                    orderType: val?.ordertype?.value,
                    filled: val?.filled?.value ?? undefined,
                    itemId: val?.orderitem?.id?.value ?? undefined,
                    currencyPairId: val?.orderitem?.item?.id?.value,
                    currencyPairName: val?.orderitem?.item?.name?.value,
                    currencyPairBaseCurrencyId: val?.orderitem?.item?.base?.id?.value ?? "",
                    currencyPairBaseCurrencyName: val?.orderitem?.item?.base?.name?.value ?? "",
                    currencyPairBaseCurrencyCode: val?.orderitem?.item?.base?.code?.value ?? "",
                    currencyPairBaseCurrencyDecimalPlaces: val?.orderitem?.item?.base?.decimalplaces?.value ?? undefined,
                    currencyPairBaseCurrencyForm: val?.orderitem?.item?.base?.form?.value ?? undefined,
                    currencyPairBaseCurrencySymbol: val?.orderitem?.item?.base?.symbol?.value ?? "",
                    currencyPairQuoteCurrencyId: val?.orderitem?.item?.quote?.id?.value ?? "",
                    currencyPairQuoteCurrencyName: val?.orderitem?.item?.quote?.name?.value ?? "",
                    currencyPairQuoteCurrencyCode: val?.orderitem?.item?.quote?.code?.value ?? "",
                    currencyPairQuoteCurrencyDecimalPlaces: val?.orderitem?.item?.quote?.decimalplaces?.value ?? undefined,
                    currencyPairQuoteCurrencyForm: val?.orderitem?.item?.quote?.form?.value ?? undefined,
                    currencyPairQuoteCurrencySymbol: val?.orderitem?.item?.quote?.symbol?.value ?? "",
                    quantityFilled: val?.quantityfilled?.value,
                    remainingFilled: val?.remainingfilled?.value,
                    remainingQuantityFilled: val?.remainingquantityfilled?.value,
                    itemPrice: val?.orderitem?.price?.value,
                    itemQuantity: val?.orderitem?.quantity?.value,
                    itemSubTotal: val?.orderitem?.subtotal?.value,
                    clientId: val?.client?.id?.value,
                    clientName: (val?.client?.firstname?.value ?? "") + " " + (val?.client?.lastname?.value ?? ""),
                    reference: val?.reference?.value,
                    status: val?.status?.value,
                    toAccountInternalId: val?.toaccount?.internalid?.value ?? "",
                    toAccountName: val?.toaccount?.name?.value ?? "",
                    toAccountId: val?.toaccount?.id?.value ?? "",
                    total: val?.total?.value,
                    tradeAction: val?.tradeaction?.value,
                    createdDate: formatDate(timestampToDate(val?.createddate?.seconds, val?.createddate?.nanos)),
                    addedBy: (val?.createdby?.firstname?.value ?? "") + " " + (val?.createdby?.lastname?.value ?? ""),
                    addedById: val?.createdby?.id?.value,
                }
                if (rc.currencyPairName == undefined) {
                    rc.currencyPairName = (rc?.currencyPairBaseCurrencyName == "" ? rc?.currencyPairBaseCurrencyId : rc?.currencyPairBaseCurrencyName + " (" + rc?.currencyPairBaseCurrencyCode + ")") + " / " + (rc?.currencyPairQuoteCurrencyName == "" ? rc?.currencyPairQuoteCurrencyId : rc?.currencyPairQuoteCurrencyName + " (" + rc?.currencyPairQuoteCurrencyCode + ")")
                }

                state.item = rc
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(exportItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload.response.success?.order && payload.metadata?.exportType == 3) {
       
                downloadFile(payload.response.success?.order+ "", payload.response.success.name?.value ?? "order" + getFileExtension(payload.metadata?.exportType))

            }

       

        })
        builder.addCase(exportItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(exportItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(cancelOrder.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                if (payload?.metadata) {
                    state.item.status = OrderStatus.CANCELED
                }
            }
        })
        builder.addCase(cancelOrder.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(cancelOrder.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = orderDetailsPageSlice.actions

export default orderDetailsPageSlice.reducer