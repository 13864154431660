// source: treasury_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.treasury_type_endpoint.AddTreasuryTypeReplay', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.AddTreasuryTypeRequest', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.AddTreasuryTypeResponse', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.AddTreasuryTypeResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.DeleteTreasuryTypeReplay', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.DeleteTreasuryTypeRequest', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.DeleteTreasuryTypeResponse', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeReplay', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeRequest', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeResponse', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypeResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypesReplay', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypesRequest', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypesResponse', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.GetTreasuryTypesResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.TreasuryType', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.UpdateTreasuryTypeReplay', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.UpdateTreasuryTypeRequest', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.UpdateTreasuryTypeResponse', null, global);
goog.exportSymbol('proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_endpoint.AddTreasuryTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_endpoint.AddTreasuryTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.AddTreasuryTypeRequest.displayName = 'proto.treasury_type_endpoint.AddTreasuryTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_endpoint.UpdateTreasuryTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.displayName = 'proto.treasury_type_endpoint.UpdateTreasuryTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.DeleteTreasuryTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.displayName = 'proto.treasury_type_endpoint.DeleteTreasuryTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypeRequest.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypesRequest.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.AddTreasuryTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.AddTreasuryTypeReplay.displayName = 'proto.treasury_type_endpoint.AddTreasuryTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.UpdateTreasuryTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.displayName = 'proto.treasury_type_endpoint.UpdateTreasuryTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.DeleteTreasuryTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.displayName = 'proto.treasury_type_endpoint.DeleteTreasuryTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_endpoint.GetTreasuryTypeReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypeReplay.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.TreasuryType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_endpoint.TreasuryType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.TreasuryType.displayName = 'proto.treasury_type_endpoint.TreasuryType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_endpoint.GetTreasuryTypesReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypesReplay.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_endpoint.AddTreasuryTypeResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_endpoint.AddTreasuryTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.AddTreasuryTypeResponse.displayName = 'proto.treasury_type_endpoint.AddTreasuryTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_endpoint.UpdateTreasuryTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.displayName = 'proto.treasury_type_endpoint.UpdateTreasuryTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_endpoint.DeleteTreasuryTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.displayName = 'proto.treasury_type_endpoint.DeleteTreasuryTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_endpoint.GetTreasuryTypeResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypeResponse.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_endpoint.GetTreasuryTypesResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypesResponse.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.displayName = 'proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest;
  return proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.AddTreasuryTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.AddTreasuryTypeRequest;
  return proto.treasury_type_endpoint.AddTreasuryTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 3:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.AddTreasuryTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isAvailable = 2;
 * @return {boolean}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated common.ExtraField extraFields = 3;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 3));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} returns this
*/
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} returns this
*/
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.AddTreasuryTypeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.UpdateTreasuryTypeRequest;
  return proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isAvailable = 3;
 * @return {boolean}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} returns this
*/
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.DeleteTreasuryTypeRequest;
  return proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypeRequest;
  return proto.treasury_type_endpoint.GetTreasuryTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeRequest}
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypesRequest;
  return proto.treasury_type_endpoint.GetTreasuryTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue search = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool order = 4;
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 numOfResults = 6;
 * @return {number}
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesRequest} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    balance: (f = msg.getBalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay;
  return proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue balance = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.getBalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.CurrencyResponse currency = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.AddTreasuryTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.AddTreasuryTypeReplay;
  return proto.treasury_type_endpoint.AddTreasuryTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.AddTreasuryTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.AddTreasuryTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.UpdateTreasuryTypeReplay;
  return proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.DeleteTreasuryTypeReplay;
  return proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypeReplay;
  return proto.treasury_type_endpoint.GetTreasuryTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue isAvailable = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.ProfileResponse createdBy = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.TreasuryType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.TreasuryType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.TreasuryType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.TreasuryType}
 */
proto.treasury_type_endpoint.TreasuryType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.TreasuryType;
  return proto.treasury_type_endpoint.TreasuryType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.TreasuryType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.TreasuryType}
 */
proto.treasury_type_endpoint.TreasuryType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.TreasuryType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.TreasuryType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.TreasuryType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
 */
proto.treasury_type_endpoint.TreasuryType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue isAvailable = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
*/
proto.treasury_type_endpoint.TreasuryType.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
 */
proto.treasury_type_endpoint.TreasuryType.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
*/
proto.treasury_type_endpoint.TreasuryType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
 */
proto.treasury_type_endpoint.TreasuryType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ProfileResponse createdBy = 4;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 4));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
*/
proto.treasury_type_endpoint.TreasuryType.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
 */
proto.treasury_type_endpoint.TreasuryType.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
*/
proto.treasury_type_endpoint.TreasuryType.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.TreasuryType} returns this
 */
proto.treasury_type_endpoint.TreasuryType.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.TreasuryType.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    treasurytypesList: jspb.Message.toObjectList(msg.getTreasurytypesList(),
    proto.treasury_type_endpoint.TreasuryType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypesReplay;
  return proto.treasury_type_endpoint.GetTreasuryTypesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_endpoint.TreasuryType;
      reader.readMessage(value,proto.treasury_type_endpoint.TreasuryType.deserializeBinaryFromReader);
      msg.addTreasurytypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreasurytypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.treasury_type_endpoint.TreasuryType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TreasuryType treasuryTypes = 1;
 * @return {!Array<!proto.treasury_type_endpoint.TreasuryType>}
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.prototype.getTreasurytypesList = function() {
  return /** @type{!Array<!proto.treasury_type_endpoint.TreasuryType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_type_endpoint.TreasuryType, 1));
};


/**
 * @param {!Array<!proto.treasury_type_endpoint.TreasuryType>} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesReplay} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypesReplay.prototype.setTreasurytypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.treasury_type_endpoint.TreasuryType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_type_endpoint.TreasuryType}
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.prototype.addTreasurytypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.treasury_type_endpoint.TreasuryType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesReplay} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesReplay.prototype.clearTreasurytypesList = function() {
  return this.setTreasurytypesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_endpoint.AddTreasuryTypeResponse.ResultCase}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_endpoint.AddTreasuryTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_endpoint.AddTreasuryTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.AddTreasuryTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_endpoint.AddTreasuryTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.AddTreasuryTypeResponse;
  return proto.treasury_type_endpoint.AddTreasuryTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_endpoint.AddTreasuryTypeReplay;
      reader.readMessage(value,proto.treasury_type_endpoint.AddTreasuryTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.AddTreasuryTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.AddTreasuryTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_endpoint.AddTreasuryTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddTreasuryTypeReplay success = 1;
 * @return {?proto.treasury_type_endpoint.AddTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_endpoint.AddTreasuryTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_endpoint.AddTreasuryTypeReplay, 1));
};


/**
 * @param {?proto.treasury_type_endpoint.AddTreasuryTypeReplay|undefined} value
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeResponse} returns this
*/
proto.treasury_type_endpoint.AddTreasuryTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_endpoint.AddTreasuryTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.AddTreasuryTypeResponse} returns this
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.AddTreasuryTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.ResultCase}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.UpdateTreasuryTypeResponse;
  return proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_endpoint.UpdateTreasuryTypeReplay;
      reader.readMessage(value,proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.UpdateTreasuryTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_endpoint.UpdateTreasuryTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryTypeReplay success = 1;
 * @return {?proto.treasury_type_endpoint.UpdateTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_endpoint.UpdateTreasuryTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_endpoint.UpdateTreasuryTypeReplay, 1));
};


/**
 * @param {?proto.treasury_type_endpoint.UpdateTreasuryTypeReplay|undefined} value
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeResponse} returns this
*/
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.UpdateTreasuryTypeResponse} returns this
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.UpdateTreasuryTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.ResultCase}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.DeleteTreasuryTypeResponse;
  return proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_endpoint.DeleteTreasuryTypeReplay;
      reader.readMessage(value,proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.DeleteTreasuryTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_endpoint.DeleteTreasuryTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteTreasuryTypeReplay success = 1;
 * @return {?proto.treasury_type_endpoint.DeleteTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_endpoint.DeleteTreasuryTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_endpoint.DeleteTreasuryTypeReplay, 1));
};


/**
 * @param {?proto.treasury_type_endpoint.DeleteTreasuryTypeReplay|undefined} value
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeResponse} returns this
*/
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.DeleteTreasuryTypeResponse} returns this
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.DeleteTreasuryTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_endpoint.GetTreasuryTypeResponse.ResultCase}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_endpoint.GetTreasuryTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_endpoint.GetTreasuryTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_endpoint.GetTreasuryTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypeResponse;
  return proto.treasury_type_endpoint.GetTreasuryTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_endpoint.GetTreasuryTypeReplay;
      reader.readMessage(value,proto.treasury_type_endpoint.GetTreasuryTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_endpoint.GetTreasuryTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuryTypeReplay success = 1;
 * @return {?proto.treasury_type_endpoint.GetTreasuryTypeReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_endpoint.GetTreasuryTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_endpoint.GetTreasuryTypeReplay, 1));
};


/**
 * @param {?proto.treasury_type_endpoint.GetTreasuryTypeReplay|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeResponse} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_endpoint.GetTreasuryTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeResponse} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_endpoint.GetTreasuryTypesResponse.ResultCase}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_endpoint.GetTreasuryTypesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_endpoint.GetTreasuryTypesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_endpoint.GetTreasuryTypesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypesResponse;
  return proto.treasury_type_endpoint.GetTreasuryTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_endpoint.GetTreasuryTypesReplay;
      reader.readMessage(value,proto.treasury_type_endpoint.GetTreasuryTypesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_endpoint.GetTreasuryTypesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuryTypesReplay success = 1;
 * @return {?proto.treasury_type_endpoint.GetTreasuryTypesReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_endpoint.GetTreasuryTypesReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_endpoint.GetTreasuryTypesReplay, 1));
};


/**
 * @param {?proto.treasury_type_endpoint.GetTreasuryTypesReplay|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesResponse} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_endpoint.GetTreasuryTypesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypesResponse} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.ResultCase}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse;
  return proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay;
      reader.readMessage(value,proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuryTypeBalanceReplay success = 1;
 * @return {?proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay, 1));
};


/**
 * @param {?proto.treasury_type_endpoint.GetTreasuryTypeBalanceReplay|undefined} value
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse} returns this
*/
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse} returns this
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_endpoint.GetTreasuryTypeBalanceResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.treasury_type_endpoint);
