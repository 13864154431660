// source: balance_reservation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.balance_reservation_endpoint.BalanceReservation', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.BalanceReservationStatusResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.DeleteReservationReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.DeleteReservationRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.DeleteReservationResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.DeleteReservationResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ExternalTransferAmountReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ExternalTransferAmountRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ExternalTransferAmountResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ExternalTransferAmountResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.FreeAmountReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.FreeAmountRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.FreeAmountResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.FreeAmountResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationsReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationsRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationsResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.GetBalanceReservationsResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ReserveAmountReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ReserveAmountRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ReserveAmountResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.ReserveAmountResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.TransferAmountReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.TransferAmountRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.TransferAmountResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.TransferAmountResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.UpdateBalanceReservationReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.UpdateBalanceReservationRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.UpdateBalanceReservationResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.ResultCase', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.WithdrawAmountReplay', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.WithdrawAmountRequest', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.WithdrawAmountResponse', null, global);
goog.exportSymbol('proto.balance_reservation_endpoint.WithdrawAmountResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.ReserveAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.balance_reservation_endpoint.ReserveAmountRequest.repeatedFields_, null);
};
goog.inherits(proto.balance_reservation_endpoint.ReserveAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.ReserveAmountRequest.displayName = 'proto.balance_reservation_endpoint.ReserveAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.repeatedFields_, null);
};
goog.inherits(proto.balance_reservation_endpoint.UpdateBalanceReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.displayName = 'proto.balance_reservation_endpoint.UpdateBalanceReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.FreeAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.FreeAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.FreeAmountRequest.displayName = 'proto.balance_reservation_endpoint.FreeAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.DeleteReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.DeleteReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.DeleteReservationRequest.displayName = 'proto.balance_reservation_endpoint.DeleteReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.GetBalanceReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.GetBalanceReservationRequest.displayName = 'proto.balance_reservation_endpoint.GetBalanceReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.GetBalanceReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.GetBalanceReservationsRequest.displayName = 'proto.balance_reservation_endpoint.GetBalanceReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.TransferAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.balance_reservation_endpoint.TransferAmountRequest.repeatedFields_, null);
};
goog.inherits(proto.balance_reservation_endpoint.TransferAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.TransferAmountRequest.displayName = 'proto.balance_reservation_endpoint.TransferAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.balance_reservation_endpoint.ExternalTransferAmountRequest.repeatedFields_, null);
};
goog.inherits(proto.balance_reservation_endpoint.ExternalTransferAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.ExternalTransferAmountRequest.displayName = 'proto.balance_reservation_endpoint.ExternalTransferAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.balance_reservation_endpoint.WithdrawAmountRequest.repeatedFields_, null);
};
goog.inherits(proto.balance_reservation_endpoint.WithdrawAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.WithdrawAmountRequest.displayName = 'proto.balance_reservation_endpoint.WithdrawAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.ReserveAmountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.ReserveAmountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.ReserveAmountReplay.displayName = 'proto.balance_reservation_endpoint.ReserveAmountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.UpdateBalanceReservationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.displayName = 'proto.balance_reservation_endpoint.UpdateBalanceReservationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.FreeAmountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.FreeAmountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.FreeAmountReplay.displayName = 'proto.balance_reservation_endpoint.FreeAmountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.DeleteReservationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.DeleteReservationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.DeleteReservationReplay.displayName = 'proto.balance_reservation_endpoint.DeleteReservationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.BalanceReservationStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.BalanceReservationStatusResponse.displayName = 'proto.balance_reservation_endpoint.BalanceReservationStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.balance_reservation_endpoint.GetBalanceReservationReplay.repeatedFields_, null);
};
goog.inherits(proto.balance_reservation_endpoint.GetBalanceReservationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.GetBalanceReservationReplay.displayName = 'proto.balance_reservation_endpoint.GetBalanceReservationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.BalanceReservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.BalanceReservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.BalanceReservation.displayName = 'proto.balance_reservation_endpoint.BalanceReservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.TransferAmountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.TransferAmountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.TransferAmountReplay.displayName = 'proto.balance_reservation_endpoint.TransferAmountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.ExternalTransferAmountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.ExternalTransferAmountReplay.displayName = 'proto.balance_reservation_endpoint.ExternalTransferAmountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.balance_reservation_endpoint.WithdrawAmountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.WithdrawAmountReplay.displayName = 'proto.balance_reservation_endpoint.WithdrawAmountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.balance_reservation_endpoint.GetBalanceReservationsReplay.repeatedFields_, null);
};
goog.inherits(proto.balance_reservation_endpoint.GetBalanceReservationsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.GetBalanceReservationsReplay.displayName = 'proto.balance_reservation_endpoint.GetBalanceReservationsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.ReserveAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.ReserveAmountResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.ReserveAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.ReserveAmountResponse.displayName = 'proto.balance_reservation_endpoint.ReserveAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.FreeAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.FreeAmountResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.FreeAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.FreeAmountResponse.displayName = 'proto.balance_reservation_endpoint.FreeAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.DeleteReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.DeleteReservationResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.DeleteReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.DeleteReservationResponse.displayName = 'proto.balance_reservation_endpoint.DeleteReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.GetBalanceReservationResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.GetBalanceReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.GetBalanceReservationResponse.displayName = 'proto.balance_reservation_endpoint.GetBalanceReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.GetBalanceReservationsResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.GetBalanceReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.GetBalanceReservationsResponse.displayName = 'proto.balance_reservation_endpoint.GetBalanceReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.TransferAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.TransferAmountResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.TransferAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.TransferAmountResponse.displayName = 'proto.balance_reservation_endpoint.TransferAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.WithdrawAmountResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.WithdrawAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.WithdrawAmountResponse.displayName = 'proto.balance_reservation_endpoint.WithdrawAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.UpdateBalanceReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.displayName = 'proto.balance_reservation_endpoint.UpdateBalanceReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.balance_reservation_endpoint.ExternalTransferAmountResponse.oneofGroups_);
};
goog.inherits(proto.balance_reservation_endpoint.ExternalTransferAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.balance_reservation_endpoint.ExternalTransferAmountResponse.displayName = 'proto.balance_reservation_endpoint.ExternalTransferAmountResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.ReserveAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.ReserveAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.ReserveAmountRequest;
  return proto.balance_reservation_endpoint.ReserveAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.ReserveAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 6:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.ReserveAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.ReserveAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 reason = 2;
 * @return {number}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.getReason = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string amount = 4;
 * @return {string}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value organization = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated common.ExtraField extraFields = 6;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 6));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ReserveAmountRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    note: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.UpdateBalanceReservationRequest;
  return proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 3:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest} returns this
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string note = 2;
 * @return {string}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest} returns this
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated common.ExtraField extraFields = 3;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 3));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest} returns this
*/
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationRequest} returns this
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.FreeAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.FreeAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.FreeAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.FreeAmountRequest}
 */
proto.balance_reservation_endpoint.FreeAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.FreeAmountRequest;
  return proto.balance_reservation_endpoint.FreeAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.FreeAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.FreeAmountRequest}
 */
proto.balance_reservation_endpoint.FreeAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.FreeAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.FreeAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.FreeAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.FreeAmountRequest} returns this
 */
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue authCode = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.FreeAmountRequest} returns this
*/
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.FreeAmountRequest} returns this
 */
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.FreeAmountRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.DeleteReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.DeleteReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationRequest}
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.DeleteReservationRequest;
  return proto.balance_reservation_endpoint.DeleteReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.DeleteReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationRequest}
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.DeleteReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.DeleteReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.DeleteReservationRequest} returns this
 */
proto.balance_reservation_endpoint.DeleteReservationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.GetBalanceReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationRequest}
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.GetBalanceReservationRequest;
  return proto.balance_reservation_endpoint.GetBalanceReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationRequest}
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.GetBalanceReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.GetBalanceReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    triggeredby: (f = msg.getTriggeredby()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.GetBalanceReservationsRequest;
  return proto.balance_reservation_endpoint.GetBalanceReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setTriggeredby(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.GetBalanceReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTriggeredby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue account = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value reason = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getReason = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value status = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int64Value triggeredBy = 9;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getTriggeredby = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setTriggeredby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearTriggeredby = function() {
  return this.setTriggeredby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasTriggeredby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int64Value referenceTransaction = 10;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getReferencetransaction = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool order = 6;
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 numOfResults = 8;
 * @return {number}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsRequest} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.balance_reservation_endpoint.TransferAmountRequest.repeatedFields_ = [3,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.TransferAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.TransferAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.TransferAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.TransferAmountRequest;
  return proto.balance_reservation_endpoint.TransferAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.TransferAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditor(value);
      break;
    case 3:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 8:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.TransferAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.TransferAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.TransferAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string creditor = 2;
 * @return {string}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.getCreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.setCreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated common.ExtraField extraFields = 3;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 3));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue message = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue description = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated common.ExtraField attachments = 8;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 8));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.TransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.TransferAmountRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.repeatedFields_ = [3,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.ExternalTransferAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    endcreditor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.ExternalTransferAmountRequest;
  return proto.balance_reservation_endpoint.ExternalTransferAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditor(value);
      break;
    case 3:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndcreditor(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 8:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.ExternalTransferAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEndcreditor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string creditor = 2;
 * @return {string}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getCreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setCreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated common.ExtraField extraFields = 3;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 3));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue message = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string endCreditor = 6;
 * @return {string}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getEndcreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setEndcreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue description = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated common.ExtraField attachments = 8;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 8));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
*/
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountRequest} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.repeatedFields_ = [3,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.WithdrawAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    treasury: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.WithdrawAmountRequest;
  return proto.balance_reservation_endpoint.WithdrawAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasury(value);
      break;
    case 3:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 8:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.WithdrawAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTreasury();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string treasury = 2;
 * @return {string}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.getTreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated common.ExtraField extraFields = 3;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 3));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
*/
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
*/
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue message = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
*/
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue description = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
*/
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated common.ExtraField attachments = 8;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 8));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
*/
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountRequest} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.ReserveAmountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.ReserveAmountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountReplay}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.ReserveAmountReplay;
  return proto.balance_reservation_endpoint.ReserveAmountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.ReserveAmountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountReplay}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.ReserveAmountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.ReserveAmountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountReplay} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountReplay} returns this
*/
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountReplay} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountReplay} returns this
*/
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountReplay} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ReserveAmountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.UpdateBalanceReservationReplay;
  return proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.FreeAmountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.FreeAmountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.FreeAmountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.FreeAmountReplay}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.FreeAmountReplay;
  return proto.balance_reservation_endpoint.FreeAmountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.FreeAmountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.FreeAmountReplay}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.FreeAmountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.FreeAmountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.FreeAmountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.FreeAmountReplay} returns this
*/
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.FreeAmountReplay} returns this
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.FreeAmountReplay} returns this
*/
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.FreeAmountReplay} returns this
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.FreeAmountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.DeleteReservationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.DeleteReservationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationReplay}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.DeleteReservationReplay;
  return proto.balance_reservation_endpoint.DeleteReservationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.DeleteReservationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationReplay}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.DeleteReservationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.DeleteReservationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.DeleteReservationReplay} returns this
*/
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationReplay} returns this
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.DeleteReservationReplay} returns this
*/
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationReplay} returns this
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.DeleteReservationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.BalanceReservationStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdtransaction: (f = msg.getCreatedtransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.BalanceReservationStatusResponse;
  return proto.balance_reservation_endpoint.BalanceReservationStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setCreatedtransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.BalanceReservationStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedtransaction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value status = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
*/
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.ProfileResponse createdBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
*/
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
*/
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.TransactionResponse createdTransaction = 4;
 * @return {?proto.common.TransactionResponse}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.getCreatedtransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 4));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
*/
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.setCreatedtransaction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservationStatusResponse} returns this
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.clearCreatedtransaction = function() {
  return this.setCreatedtransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservationStatusResponse.prototype.hasCreatedtransaction = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.GetBalanceReservationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: (f = msg.getAccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    note: (f = msg.getNote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.balance_reservation_endpoint.BalanceReservationStatusResponse.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    triggeredby: (f = msg.getTriggeredby()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.GetBalanceReservationReplay;
  return proto.balance_reservation_endpoint.GetBalanceReservationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 6:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 8:
      var value = new proto.balance_reservation_endpoint.BalanceReservationStatusResponse;
      reader.readMessage(value,proto.balance_reservation_endpoint.BalanceReservationStatusResponse.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 9:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 10:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 11:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setTriggeredby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.GetBalanceReservationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.balance_reservation_endpoint.BalanceReservationStatusResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getTriggeredby();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.AccountResponse account = 2;
 * @return {?proto.common.AccountResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getAccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 2));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value reason = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getReason = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue note = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getNote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasNote = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue amount = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.ProfileResponse createdBy = 6;
 * @return {?proto.common.ProfileResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 6));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BalanceReservationStatusResponse status = 8;
 * @return {?proto.balance_reservation_endpoint.BalanceReservationStatusResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getStatus = function() {
  return /** @type{?proto.balance_reservation_endpoint.BalanceReservationStatusResponse} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.BalanceReservationStatusResponse, 8));
};


/**
 * @param {?proto.balance_reservation_endpoint.BalanceReservationStatusResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 9;
 * @return {?proto.common.TransactionResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 9));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated common.ExtraField extraFields = 10;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 10));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.TransactionResponse triggeredBy = 11;
 * @return {?proto.common.TransactionResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.getTriggeredby = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 11));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.setTriggeredby = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.clearTriggeredby = function() {
  return this.setTriggeredby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationReplay.prototype.hasTriggeredby = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.BalanceReservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.BalanceReservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.BalanceReservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: (f = msg.getAccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.balance_reservation_endpoint.BalanceReservationStatusResponse.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    note: (f = msg.getNote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    triggeredby: (f = msg.getTriggeredby()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation}
 */
proto.balance_reservation_endpoint.BalanceReservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.BalanceReservation;
  return proto.balance_reservation_endpoint.BalanceReservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.BalanceReservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation}
 */
proto.balance_reservation_endpoint.BalanceReservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new proto.balance_reservation_endpoint.BalanceReservationStatusResponse;
      reader.readMessage(value,proto.balance_reservation_endpoint.BalanceReservationStatusResponse.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 8:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 10:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setTriggeredby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.BalanceReservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.BalanceReservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.BalanceReservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.balance_reservation_endpoint.BalanceReservationStatusResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTriggeredby();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.AccountResponse account = 2;
 * @return {?proto.common.AccountResponse}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getAccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 2));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value reason = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getReason = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue amount = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse createdBy = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BalanceReservationStatusResponse status = 7;
 * @return {?proto.balance_reservation_endpoint.BalanceReservationStatusResponse}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getStatus = function() {
  return /** @type{?proto.balance_reservation_endpoint.BalanceReservationStatusResponse} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.BalanceReservationStatusResponse, 7));
};


/**
 * @param {?proto.balance_reservation_endpoint.BalanceReservationStatusResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 8;
 * @return {?proto.common.TransactionResponse}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 8));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue note = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getNote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasNote = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.TransactionResponse triggeredBy = 10;
 * @return {?proto.common.TransactionResponse}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.getTriggeredby = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 10));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
*/
proto.balance_reservation_endpoint.BalanceReservation.prototype.setTriggeredby = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.BalanceReservation} returns this
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.clearTriggeredby = function() {
  return this.setTriggeredby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.BalanceReservation.prototype.hasTriggeredby = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.TransferAmountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.TransferAmountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.TransferAmountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactioninternalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.TransferAmountReplay}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.TransferAmountReplay;
  return proto.balance_reservation_endpoint.TransferAmountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.TransferAmountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.TransferAmountReplay}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactioninternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.TransferAmountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.TransferAmountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.TransferAmountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactioninternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionInternalId = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.getTransactioninternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountReplay} returns this
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.setTransactioninternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 transactionId = 2;
 * @return {number}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountReplay} returns this
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountReplay} returns this
*/
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.TransferAmountReplay} returns this
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.TransferAmountReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.ExternalTransferAmountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactioninternalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.ExternalTransferAmountReplay;
  return proto.balance_reservation_endpoint.ExternalTransferAmountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactioninternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.ExternalTransferAmountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactioninternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionInternalId = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.getTransactioninternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.setTransactioninternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 transactionId = 2;
 * @return {number}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay} returns this
*/
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountReplay} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.WithdrawAmountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactioninternalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountReplay}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.WithdrawAmountReplay;
  return proto.balance_reservation_endpoint.WithdrawAmountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountReplay}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactioninternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.WithdrawAmountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactioninternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionInternalId = 1;
 * @return {string}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.getTransactioninternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountReplay} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.setTransactioninternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 transactionId = 2;
 * @return {number}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountReplay} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountReplay} returns this
*/
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountReplay} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.WithdrawAmountReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.GetBalanceReservationsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    balancereservationsList: jspb.Message.toObjectList(msg.getBalancereservationsList(),
    proto.balance_reservation_endpoint.BalanceReservation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsReplay}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.GetBalanceReservationsReplay;
  return proto.balance_reservation_endpoint.GetBalanceReservationsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsReplay}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.BalanceReservation;
      reader.readMessage(value,proto.balance_reservation_endpoint.BalanceReservation.deserializeBinaryFromReader);
      msg.addBalancereservations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.GetBalanceReservationsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalancereservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.balance_reservation_endpoint.BalanceReservation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BalanceReservation balanceReservations = 1;
 * @return {!Array<!proto.balance_reservation_endpoint.BalanceReservation>}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.prototype.getBalancereservationsList = function() {
  return /** @type{!Array<!proto.balance_reservation_endpoint.BalanceReservation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.balance_reservation_endpoint.BalanceReservation, 1));
};


/**
 * @param {!Array<!proto.balance_reservation_endpoint.BalanceReservation>} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsReplay} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.prototype.setBalancereservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.balance_reservation_endpoint.BalanceReservation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.balance_reservation_endpoint.BalanceReservation}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.prototype.addBalancereservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.balance_reservation_endpoint.BalanceReservation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsReplay} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsReplay.prototype.clearBalancereservationsList = function() {
  return this.setBalancereservationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.ReserveAmountResponse.ResultCase}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.ReserveAmountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.ReserveAmountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.ReserveAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.ReserveAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.ReserveAmountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountResponse}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.ReserveAmountResponse;
  return proto.balance_reservation_endpoint.ReserveAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.ReserveAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountResponse}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.ReserveAmountReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.ReserveAmountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.ReserveAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.ReserveAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.ReserveAmountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReserveAmountReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.ReserveAmountReplay}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.ReserveAmountReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.ReserveAmountReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.ReserveAmountReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.ReserveAmountResponse} returns this
*/
proto.balance_reservation_endpoint.ReserveAmountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.ReserveAmountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ReserveAmountResponse} returns this
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ReserveAmountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.FreeAmountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.FreeAmountResponse.ResultCase}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.FreeAmountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.FreeAmountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.FreeAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.FreeAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.FreeAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.FreeAmountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.FreeAmountResponse}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.FreeAmountResponse;
  return proto.balance_reservation_endpoint.FreeAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.FreeAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.FreeAmountResponse}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.FreeAmountReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.FreeAmountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.FreeAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.FreeAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.FreeAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.FreeAmountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional FreeAmountReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.FreeAmountReplay}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.FreeAmountReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.FreeAmountReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.FreeAmountReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.FreeAmountResponse} returns this
*/
proto.balance_reservation_endpoint.FreeAmountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.FreeAmountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.FreeAmountResponse} returns this
 */
proto.balance_reservation_endpoint.FreeAmountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.FreeAmountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.DeleteReservationResponse.ResultCase}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.DeleteReservationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.DeleteReservationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.DeleteReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.DeleteReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.DeleteReservationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationResponse}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.DeleteReservationResponse;
  return proto.balance_reservation_endpoint.DeleteReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.DeleteReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationResponse}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.DeleteReservationReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.DeleteReservationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.DeleteReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.DeleteReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.DeleteReservationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteReservationReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.DeleteReservationReplay}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.DeleteReservationReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.DeleteReservationReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.DeleteReservationReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.DeleteReservationResponse} returns this
*/
proto.balance_reservation_endpoint.DeleteReservationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.DeleteReservationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.DeleteReservationResponse} returns this
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.DeleteReservationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.GetBalanceReservationResponse.ResultCase}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.GetBalanceReservationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.GetBalanceReservationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.GetBalanceReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.GetBalanceReservationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.GetBalanceReservationResponse;
  return proto.balance_reservation_endpoint.GetBalanceReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.GetBalanceReservationReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.GetBalanceReservationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.GetBalanceReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.GetBalanceReservationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBalanceReservationReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.GetBalanceReservationReplay}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.GetBalanceReservationReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.GetBalanceReservationReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.GetBalanceReservationReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationResponse} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.GetBalanceReservationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationResponse} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.GetBalanceReservationsResponse.ResultCase}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.GetBalanceReservationsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.GetBalanceReservationsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.GetBalanceReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.GetBalanceReservationsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.GetBalanceReservationsResponse;
  return proto.balance_reservation_endpoint.GetBalanceReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsResponse}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.GetBalanceReservationsReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.GetBalanceReservationsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.GetBalanceReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.GetBalanceReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.GetBalanceReservationsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBalanceReservationsReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.GetBalanceReservationsReplay}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.GetBalanceReservationsReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.GetBalanceReservationsReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.GetBalanceReservationsReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsResponse} returns this
*/
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.GetBalanceReservationsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.GetBalanceReservationsResponse} returns this
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.GetBalanceReservationsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.TransferAmountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.TransferAmountResponse.ResultCase}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.TransferAmountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.TransferAmountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.TransferAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.TransferAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.TransferAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.TransferAmountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.TransferAmountResponse}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.TransferAmountResponse;
  return proto.balance_reservation_endpoint.TransferAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.TransferAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.TransferAmountResponse}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.TransferAmountReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.TransferAmountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.TransferAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.TransferAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.TransferAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.TransferAmountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional TransferAmountReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.TransferAmountReplay}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.TransferAmountReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.TransferAmountReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.TransferAmountReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.TransferAmountResponse} returns this
*/
proto.balance_reservation_endpoint.TransferAmountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.TransferAmountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.TransferAmountResponse} returns this
 */
proto.balance_reservation_endpoint.TransferAmountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.TransferAmountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.WithdrawAmountResponse.ResultCase}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.WithdrawAmountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.WithdrawAmountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.WithdrawAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.WithdrawAmountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountResponse}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.WithdrawAmountResponse;
  return proto.balance_reservation_endpoint.WithdrawAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountResponse}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.WithdrawAmountReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.WithdrawAmountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.WithdrawAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.WithdrawAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.WithdrawAmountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional WithdrawAmountReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.WithdrawAmountReplay}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.WithdrawAmountReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.WithdrawAmountReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.WithdrawAmountReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountResponse} returns this
*/
proto.balance_reservation_endpoint.WithdrawAmountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.WithdrawAmountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.WithdrawAmountResponse} returns this
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.WithdrawAmountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.ResultCase}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationResponse}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.UpdateBalanceReservationResponse;
  return proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationResponse}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.UpdateBalanceReservationReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.UpdateBalanceReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.UpdateBalanceReservationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateBalanceReservationReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.UpdateBalanceReservationReplay}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.UpdateBalanceReservationReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.UpdateBalanceReservationReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.UpdateBalanceReservationReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationResponse} returns this
*/
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.UpdateBalanceReservationResponse} returns this
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.UpdateBalanceReservationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.balance_reservation_endpoint.ExternalTransferAmountResponse.ResultCase}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.prototype.getResultCase = function() {
  return /** @type {proto.balance_reservation_endpoint.ExternalTransferAmountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.balance_reservation_endpoint.ExternalTransferAmountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.balance_reservation_endpoint.ExternalTransferAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.balance_reservation_endpoint.ExternalTransferAmountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountResponse}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.balance_reservation_endpoint.ExternalTransferAmountResponse;
  return proto.balance_reservation_endpoint.ExternalTransferAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountResponse}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.balance_reservation_endpoint.ExternalTransferAmountReplay;
      reader.readMessage(value,proto.balance_reservation_endpoint.ExternalTransferAmountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.balance_reservation_endpoint.ExternalTransferAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.balance_reservation_endpoint.ExternalTransferAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.balance_reservation_endpoint.ExternalTransferAmountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalTransferAmountReplay success = 1;
 * @return {?proto.balance_reservation_endpoint.ExternalTransferAmountReplay}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.balance_reservation_endpoint.ExternalTransferAmountReplay} */ (
    jspb.Message.getWrapperField(this, proto.balance_reservation_endpoint.ExternalTransferAmountReplay, 1));
};


/**
 * @param {?proto.balance_reservation_endpoint.ExternalTransferAmountReplay|undefined} value
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountResponse} returns this
*/
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.balance_reservation_endpoint.ExternalTransferAmountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.balance_reservation_endpoint.ExternalTransferAmountResponse} returns this
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.balance_reservation_endpoint.ExternalTransferAmountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.balance_reservation_endpoint);
