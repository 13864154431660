import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';
import { TableState } from '../../../../../common/Table/TableSate';
import { number } from 'yup';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../../../repository/Accountant/CurrencyServiceClientPb';
import { TreasuryEndpointClient } from '../../../../../../repository/Accountant/TreasuryServiceClientPb';
import { CloseTreasuryRequest, CloseTreasuryResponse, GetTreasuriesRequest, UpdateTreasuryLimitationResponse, GetTreasuriesResponse, UpdateTreasuryLimitationRequest, UpdateTreasuryStatusRequest, UpdateTreasuryStatusResponse } from '../../../../../../repository/Accountant/treasury_pb';
import { GetTreasuryTypesRequest, GetTreasuryTypesResponse } from '../../../../../../repository/Accountant/treasury_type_pb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../../../repository/Accountant/limitation_pb';
import { LimitationEndpointClient } from '../../../../../../repository/Accountant/LimitationServiceClientPb';
import { TreasuryStatus } from '../../../../../../app/Enums';
import { TreasuryOperationEndpointClient } from '../../../../../../repository/Accountant/Treasury_operationServiceClientPb';
import { GetStatementRequest, GetStatementResponse } from '../../../../../../repository/Accountant/account_operation_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../../../repository/Accountant/account_pb';
import { AccountEndpointClient } from '../../../../../../repository/Accountant/AccountServiceClientPb';
import { AccountOperationEndpointClient } from '../../../../../../repository/Accountant/Account_operationServiceClientPb';


export interface AccountStatementPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    treasuries: TableState
    accounts: TableState
    operationFilterOption: { key: number, text: string },
    fromFilterOption: { value: string, text: string },
    toFilterOption: { value: string, text: string },
}

const initialState: AccountStatementPageState = {

    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    treasuries: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    fromFilterOption: { value: "", text: "" },
    toFilterOption: { value: "", text: "" },
    operationFilterOption: { key: 0, text: "" },

}

const client = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const treasuryClient = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<GetStatementResponse.AsObject, APIRequest<GetStatementRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/statement/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getStatement(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/statement/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getTreasuries = createAsyncThunk<GetTreasuriesResponse.AsObject, APIRequest<GetTreasuriesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/statement/treasuries/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await treasuryClient.getTreasuries(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



const getFilters = (state: AccountStatementPageState): any[] => {
    const selected = [];

    if (state.operationFilterOption.key != 0) {
        selected.push({ key: 1, name: state.operationFilterOption.text });
    }
    if (state.accounts.selected.length > 0) {
        selected.push({ key: 2, name: state.accounts.selected.at(0).name });
    }
    if (state.treasuries.selected.length > 0) {
        selected.push({ key: 3, name: state.treasuries.selected.at(0).name });
    }
    if (state.fromFilterOption.value && state.fromFilterOption.value != "") {
        selected.push({ key: 4, name: state.fromFilterOption.text });
    }
    if (state.toFilterOption.value && state.toFilterOption.value != "") {
        selected.push({ key: 5, name: state.toFilterOption.text });
    }


    return selected;
}

export const accountStatementPageSlice = createSlice({
    name: 'pages/accountant/accountDetails/statement',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.treasuries = initialState.treasuries;
            state.accounts = initialState.accounts;
            state.fromFilterOption = initialState.fromFilterOption;
            state.toFilterOption = initialState.toFilterOption;
            state.operationFilterOption = initialState.operationFilterOption;
            state.filters = initialState.filters;

        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

        resetTreasuries: (state) => {
            state.treasuries.items = [];
            state.treasuries.hasMore = true;
        },


        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },

        setSelectedAccountItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
            state.filters = getFilters(state);
        },

        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },
        resetTreasuriesSet: (state) => {
            state.treasuries.items = [];
            state.treasuries.hasMore = true;
        },


        setIsFilteredTreasurySet: (state, action: PayloadAction<boolean>) => {
            state.treasuries.isFilteredSet = action.payload;
        },

        setSelectedTreasuryItems: (state, action: PayloadAction<any[]>) => {
            state.treasuries.selected = action.payload;
            state.filters = getFilters(state);

        },


        setIsFilteredAccountSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },

        setOperationFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.operationFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setFromFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.fromFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setToFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.toFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        },
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {


        builder.addCase(getTreasuries.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasuriesList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        nature: val.nature?.id?.value ?? undefined,
                        treasuryTypeId: val.treasurytype?.id?.value ?? "",
                        treasuryTypeName: val.treasurytype?.name?.value ?? "",
                        status: val.status?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.treasuries.numberOfResults) {
                    state.treasuries.hasMore = false;
                }
                state.treasuries.items = state.treasuries.items.concat(r);
            } else {
                state.treasuries.hasMore = false;

            }
            state.treasuries.isFetching = false

        })
        builder.addCase(getTreasuries.rejected, (state, action) => {
            state.treasuries.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuries.pending, (state, action) => {
            state.treasuries.isFetching = true;
            //  state.message = undefined;
        })
        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.transactionsList.map(val => {
                    // console.log(val)
                    let r = {
                        id: val.id,
                        internalId: val.internalid,
                        amount: val?.amount?.value ?? undefined,
                        debtorIsTreasury: val?.debtor?.istreasury?.value ?? undefined,
                        creditorIsTreasury: val?.creditor?.istreasury?.value ?? undefined,
                        debitAmount: val.amount?.value ?? undefined,
                        creditAmount: val.amount?.value ?? undefined,
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        operation: val.operation?.value ?? undefined,
                        referenceTransactionId: val.referencetransaction?.id?.value ?? undefined,
                        referenceTransactionInternalId: val.referencetransaction?.internalid?.value ?? "",
                        debtorId: val.debtor?.id?.value ?? undefined,
                        debtorInternalId: val.debtor?.internalid?.value ?? "",
                        debtorName: val.debtor?.name?.value ?? "",
                        debtorBalanceBefore: val.debtor?.balancebefore?.value ?? undefined,
                        debtorBalanceAfter: val.debtor?.balanceafter?.value ?? undefined,
                        debtorDescription: val.debtor?.description?.value ?? undefined,
                        creditorId: val.creditor?.id?.value ?? undefined,
                        creditorInternalId: val.creditor?.internalid?.value ?? "",
                        creditorName: val.creditor?.name?.value ?? "",
                        creditorBalanceBefore: val.creditor?.balancebefore?.value ?? undefined,
                        creditorBalanceAfter: val.creditor?.balanceafter?.value ?? undefined,
                        creditorDescription: val.creditor?.description?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }

                    //console.log(r)
                    return r;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.set.items = l
                //console.log(state.items)

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })


    }
})

export const { reset, dismissMessage, setMessage, resetAccountsSet, setIsFilteredAccountSet, setSelectedAccountItems, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, resetTreasuries, resetTreasuriesSet, setIsFilteredTreasurySet, setSelectedTreasuryItems, setFromFilterOption, setOperationFilterOption, setToFilterOption } = accountStatementPageSlice.actions

export default accountStatementPageSlice.reducer


