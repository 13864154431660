import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../app/Api';
import { base64ToArrayBuffer, downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../app/Helpers';

import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { TreasuryEndpointClient } from '../../../../repository/Accountant/TreasuryServiceClientPb';
import { ReportEndpointClient } from '../../../../repository/Accountant/ReportServiceClientPb';
import { ExportTreasuryBalanceReportRequest, ExportTreasuryBalanceReportResponse, GetTreasuryBalanceReportRequest, GetTreasuryBalanceReportResponse } from '../../../../repository/Accountant/report_pb';
import { GetTreasuriesRequest, GetTreasuriesResponse } from '../../../../repository/Accountant/treasury_pb';
import { TreasuryTypeEndpointClient } from '../../../../repository/Accountant/Treasury_typeServiceClientPb';
import { GetTreasuryTypesRequest, GetTreasuryTypesResponse } from '../../../../repository/Accountant/treasury_type_pb';


export interface TreasuryBalanceReportPageState {
    set: TableState
    message?: ApiMessage,
    currencies: TableState
    treasuries: TableState
    isChangeStateLoading: boolean
    totals: any
    treasuryTypes: TableState


}

const initialState: TreasuryBalanceReportPageState = {

    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    message: undefined,
    totals: undefined,
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    treasuries: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    treasuryTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },


}
const client = new ReportEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientTreasury = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientTreasuryType = new TreasuryTypeEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getTreasuryTypes = createAsyncThunk<GetTreasuryTypesResponse.AsObject, APIRequest<GetTreasuryTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/treasuryBalanceReport/treasuryTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientTreasuryType.getTreasuryTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const getItems = createAsyncThunk<APIResponse<GetTreasuryBalanceReportResponse.AsObject>, APIRequest<GetTreasuryBalanceReportRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/treasuryBalanceReport/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getTreasuryBalanceReport(req.body, req.headers ?? {});
                return { metadata: { grouping: req.body.getGroupby() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getTreasuries = createAsyncThunk<GetTreasuriesResponse.AsObject, APIRequest<GetTreasuriesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/treasuryBalanceReport/treasuries/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientTreasury.getTreasuries(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/treasuryBalanceReport/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const exportReport = createAsyncThunk<APIResponse<ExportTreasuryBalanceReportResponse.AsObject>, APIRequest<ExportTreasuryBalanceReportRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/reports/treasuryBalanceReport/export',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.exportTreasuryBalanceReport(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { exportType: req.body.getExporttype() } };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const treasuryBalanceReportPageSlice = createSlice({
    name: 'pages/reports/treasuryBalanceReport',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.currencies = initialState.currencies;
            state.treasuries = initialState.treasuries;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.totals = initialState.totals;
            state.treasuryTypes = initialState.treasuryTypes;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },


        resetTreasuriesSet: (state) => {
            state.treasuries.items = [];
            state.treasuries.hasMore = true;
        },

        resetTreasuryTypes: (state) => {
            state.treasuryTypes.items = [];
            state.treasuryTypes.hasMore = true;
        },


        setIsFilteredTreasuryTypesSet: (state, action: PayloadAction<boolean>) => {
            state.treasuryTypes.isFilteredSet = action.payload;
        },

        setSelectedTreasuryTypeItems: (state, action: PayloadAction<any[]>) => {
            state.treasuryTypes.selected = action.payload;

        },

        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;
        },
        setSelectedTreasuryItems: (state, action: PayloadAction<any[]>) => {
            state.treasuries.selected = action.payload;
        },

        setIsFilteredCurrencySet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },

        setIsFilteredTreasurySet: (state, action: PayloadAction<boolean>) => {
            state.treasuries.isFilteredSet = action.payload;
        },


        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getTreasuries.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasuriesList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        nature: val.nature?.id?.value ?? undefined,
                        treasuryTypeId: val.treasurytype?.id?.value ?? "",
                        treasuryTypeName: val.treasurytype?.name?.value ?? "",
                        status: val.status?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.treasuries.numberOfResults) {
                    state.treasuries.hasMore = false;
                }
                state.treasuries.items = state.treasuries.items.concat(r);
            } else {
                state.treasuries.hasMore = false;

            }
            state.treasuries.isFetching = false

        })
        builder.addCase(getTreasuries.rejected, (state, action) => {
            state.treasuries.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuries.pending, (state, action) => {
            state.treasuries.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.response.success?.reportList.map(val => {
                    let r = {
                        date: formatDate(timestampToDate(val.date?.seconds, val.date?.nanos, payload?.metadata?.grouping == 3 ), payload?.metadata?.grouping == 0 ? 'YYYY-MM-DD' : payload?.metadata?.grouping == 1 ? 'YYYY-MM' : payload?.metadata?.grouping == 2 ? 'YYYY' : payload?.metadata?.grouping == 3 ? "YYYY-MM-DD HH:00:00" : 'YYYY-MM-DD'),
                        closingBalance: val.closingbalance?.value,
                        openningBalance: val.openningbalance?.value,
                        totalCredit: val.totalcredit?.value,
                        totalDebit: val.totaldebit?.value,
                        totalNumberOfCreditOperations: val.totalnumberofcreditoperations?.value,
                        totalNumberOfDebitOperations: val.totalnumberofdebitoperations?.value,
                        currency: state.currencies.selected.at(0)
                    }
                    //console.log(r)
                    return r;
                }
                ) as any[])

                if (r.length > 0) {
                    state.totals = {
                        totalCredit: payload?.response?.success?.totalcredit?.value,
                        totalDebit: payload?.response?.success?.totaldebit?.value,
                        totalNumberOfCreditOperations: payload?.response?.success?.totalnumberofcreditoperations?.value,
                        totalNumberOfDebitOperations: payload?.response?.success?.totalnumberofdebitoperations?.value,
                        currency: state.currencies.selected.at(0)
                    }
                } else {
                    state.totals = undefined;
                }
                state.set.items = r

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(exportReport.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload.response.success?.report) {
                downloadFile(payload.response.success?.report + "", payload.response.success.name?.value ?? "report" + getFileExtension(payload.metadata?.exportType))

            }

        })
        builder.addCase(exportReport.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(exportReport.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

                builder.addCase(getTreasuryTypes.fulfilled, (state, { payload }) => {
                    if (payload) {
                        var r = (payload?.success?.treasurytypesList.map(val => {
                            return {
                                id: val.id,
                                name: val.name?.value ?? "",
                                isAvailable: val.isavailable?.value ?? undefined,
                                createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                                addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                                addedById: val.createdby?.id?.value,
                            }
                        }
                        ) as any[])
        
                        if (r.length < state.treasuryTypes.numberOfResults) {
                            state.treasuryTypes.hasMore = false;
                        }
                        state.treasuryTypes.items = state.treasuryTypes.items.concat(r);
        
                    } else {
                        state.treasuryTypes.hasMore = false;
                    }
                    state.treasuryTypes.isFetching = false
        
                })
                builder.addCase(getTreasuryTypes.rejected, (state, action) => {
                    state.treasuryTypes.isFetching = false;
                    if (action.payload) {
                        //state.message = action.payload;
                    }
                })
                builder.addCase(getTreasuryTypes.pending, (state, action) => {
                    state.treasuryTypes.isFetching = true;
                    //state.message = undefined;
                })
        
    }
})

export const { reset, dismissMessage, setMessage, resetTreasuriesSet, resetSet, setSelectedTreasuryTypeItems, setIsFilteredTreasuryTypesSet, resetTreasuryTypes, setIsFilteredCurrencySet, setSelectedTreasuryItems, resetCurrencies, setIsFilteredTreasurySet, setSelectedCurrencyItems } = treasuryBalanceReportPageSlice.actions

export default treasuryBalanceReportPageSlice.reducer


