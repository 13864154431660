import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL, APIResponse } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { FeeEndpointClient } from '../../../../../repository/Accountant/FeeServiceClientPb';
import { AddFeeCollectionAccountRequest, AddFeeCollectionAccountResponse, AddFeeRequest, AddFeeResponse, GetFeeRequest, GetFeeResponse, UpdateFeeRequest, UpdateFeeResponse } from '../../../../../repository/Accountant/fee_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../../repository/Accountant/account_pb';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { TableState } from '../../../../common/Table/TableSate';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../../repository/Accountant/CurrencyServiceClientPb';
import { DepositRequest, DepositResponse, GetTransactionSummaryRequest, GetTransactionSummaryResponse, TransferRequest, TransferResponse } from '../../../../../repository/Accountant/account_operation_pb';
import { AccountOperationEndpointClient } from '../../../../../repository/Accountant/Account_operationServiceClientPb';
import { TreasuryEndpointClient } from '../../../../../repository/Accountant/TreasuryServiceClientPb';
import { FileEndpointClient } from '../../../../../repository/Accountant/FileServiceClientPb';
import { GetTreasuriesRequest, GetTreasuriesResponse } from '../../../../../repository/Accountant/treasury_pb';
import { AddFileRequest, AddFileResponse, DeleteFileResponse, DeleteFileRequest } from '../../../../../repository/Accountant/file_pb';

export interface DepositFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    treasuries: TableState,
    accounts: TableState,
    confirmation: any[],
    attachments: any[],

}

const initialState: DepositFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    treasuries: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    confirmation: [],
    attachments: []

}

const client = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const treasuryClient = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const fileClient = new FileEndpointClient(ACCOUNTANT_API_URL, null, null);


export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/deposit/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getTransactionSummary = createAsyncThunk<APIResponse<GetTransactionSummaryResponse.AsObject>, APIRequest<GetTransactionSummaryRequest>, {

    rejectValue: APIResponse<ApiMessage>
}>(
    'forms/accountant/operations/deposit/getTransactionSummary',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getTransactionSummary(req.body, req.headers ?? {});
                return {
                    metadata: {
                        transactions: req.body.getTransactionsList()?.map(v => {
                            return { amount: v.getAmount() }
                        })
                    }, response: r.toObject()
                };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue({
                        response: message, metadata: {
                            transactions: req.body.getTransactionsList()?.map(v => {
                                return { amount: v.getAmount() }
                            })
                        }
                    })
                } catch (err) {

                    return thunkApi.rejectWithValue({
                        response: { body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage, metadata: {
                            transactions: {
                                amount: req.body.getTransactionsList()?.map(v => {
                                    return { amount: v.getAmount() }
                                })
                            }
                        }
                    })
                }
            }
        }
        return await callReq();
    }
)


export const getTreasuries = createAsyncThunk<GetTreasuriesResponse.AsObject, APIRequest<GetTreasuriesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/deposit/treasuries/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await treasuryClient.getTreasuries(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const add = createAsyncThunk<DepositResponse.AsObject, APIRequest<DepositRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/deposit/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.deposit(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const uploadFile = createAsyncThunk<APIResponse<AddFileResponse.AsObject>, APIRequest<AddFileRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/deposit/uploadFile',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await fileClient.add(req.body, req.headers ?? {});

                return { response: r.toObject(), metadata: req.metadata } as APIResponse<AddFileResponse.AsObject>;
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }


)


export const deleteFile = createAsyncThunk<APIResponse<DeleteFileResponse.AsObject>, APIRequest<DeleteFileRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/deposit/deleteFile',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await fileClient.delete(req.body, req.headers ?? {});

                return { response: r.toObject(), metadata: req.metadata } as APIResponse<DeleteFileResponse.AsObject>;
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }


)


export const depositFormSlice = createSlice({
    name: 'forms/accountant/operations/deposit',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.accounts = initialState.accounts;
            state.treasuries = initialState.treasuries;
            state.confirmation = initialState.confirmation;
            state.attachments = initialState.attachments;

        },
        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },

        resetTreasuriesSet: (state) => {
            state.treasuries.items = [];
            state.treasuries.hasMore = true;
        },

        setSelectedAccountItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
        },

        setIsFilteredTreasurySet: (state, action: PayloadAction<boolean>) => {
            state.treasuries.isFilteredSet = action.payload;
        },

        setSelectedTreasuryItems: (state, action: PayloadAction<any[]>) => {
            state.treasuries.selected = action.payload;
        },

        setIsFilteredAccountSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },

        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {

            }
            state.stage1 = stage1;
        },

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
        resetMessage: (state) => {
            state.message = undefined;
        },
        removeFile: (state, action: PayloadAction<string>) => {
            var r = state.attachments.filter(item => item.id != action.payload);
            state.attachments = r


    
        },

    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            // if (payload)
            //state.message = toApiMessage(payload.success?.);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            if (action.payload) {
                //state.message = action.payload;
            }
            state.accounts.isFetching = false;

        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getTransactionSummary.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = (payload.response?.success?.transactionsummaryList.map(val => {

                    return {
                        amount: val?.amount?.value,
                        creditorfeeValue: val?.creditorfee?.fee?.value,
                        creditorfeeId: val?.creditorfee?.id?.value,
                        creditorfeeName: val?.creditorfee?.name?.value,
                        debtorfeeValue: val?.debtorfee?.fee?.value,
                        debtorfeeId: val?.debtorfee?.id?.value,
                        debtorfeeName: val?.debtorfee?.name?.value,
                        currencyId: val?.currency?.id?.value ?? "",
                        currencyName: val?.currency?.name?.value ?? "",
                        currencyCode: val?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val?.currency?.form?.value ?? undefined,
                        currencySymbol: val?.currency?.symbol?.value ?? "",
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));

                state.confirmation = r;

            }

        })
        builder.addCase(getTransactionSummary.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload?.response.data == 403) {
                var r = (action.payload?.metadata?.transactions?.map((val: any) => {
                    return {
                        amount: val?.amount,
                    }
                }
                ) as any[])
                state.confirmation = r;
                return;
            }
            if (action.payload) {
                state.message = action.payload.response;
            }
        })
        builder.addCase(getTransactionSummary.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getTreasuries.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasuriesList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        nature: val.nature?.id?.value ?? undefined,
                        treasuryTypeId: val.treasurytype?.id?.value ?? "",
                        treasuryTypeName: val.treasurytype?.name?.value ?? "",
                        status: val.status?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.treasuries.numberOfResults) {
                    state.treasuries.hasMore = false;
                }
                state.treasuries.items = state.treasuries.items.concat(r);
            } else {
                state.treasuries.hasMore = false;

            }
            state.treasuries.isFetching = false

        })
        builder.addCase(getTreasuries.rejected, (state, action) => {
            state.treasuries.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuries.pending, (state, action) => {
            state.treasuries.isFetching = true;
            //  state.message = undefined;
        })

        builder.addCase(uploadFile.fulfilled, (state, { payload }) => {
            var r = state.attachments.filter(item => item.id == payload.metadata?.id)[0];
            if (r) {
                r.isLoading = false;
                r.fileId = payload.response?.success?.image?.id?.value
            }
        })
        builder.addCase(uploadFile.rejected, (state, action) => {

            state.attachments = state.attachments.filter(item => item.id != action.meta.arg.metadata?.id);
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(uploadFile.pending, (state, { meta }) => {

            state.attachments = state.attachments.concat([{ id: meta.arg?.metadata?.id, mime: meta.arg?.metadata?.mime, isLoading: true, originalName: meta.arg?.metadata?.originalName, fileId: undefined, size: meta.arg?.metadata?.size }]);
            state.message = undefined;
        })

        builder.addCase(deleteFile.fulfilled, (state, { payload }) => {
            var r = state.attachments.filter(item => item.id != payload.metadata?.id);
            state.attachments = r

        })
        builder.addCase(deleteFile.rejected, (state, action) => {


            var r = state.attachments.filter(item => item.id == action.meta?.arg?.metadata?.id)[0];
            if (r) {
                r.isLoading = false;
            }

            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteFile.pending, (state, { meta }) => {

            var r = state.attachments.filter(item => item.id == meta.arg?.metadata?.id)[0];
            if (r) {
                r.isLoading = true;
            }

            state.message = undefined;
        })




    }
})

export const { reset, dismissMessage, resetMessage, setStage1, removeFile, setMessage, resetAccountsSet, resetTreasuriesSet, setIsFilteredAccountSet, setIsFilteredTreasurySet, setSelectedAccountItems, setSelectedTreasuryItems } = depositFormSlice.actions

export default depositFormSlice.reducer


