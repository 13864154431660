// source: report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.report_endpoint.AccountPeriod', null, global);
goog.exportSymbol('proto.report_endpoint.ExportAccountBalanceReportReplay', null, global);
goog.exportSymbol('proto.report_endpoint.ExportAccountBalanceReportRequest', null, global);
goog.exportSymbol('proto.report_endpoint.ExportAccountBalanceReportResponse', null, global);
goog.exportSymbol('proto.report_endpoint.ExportAccountBalanceReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTransactionTotalsReportReplay', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTransactionTotalsReportRequest', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTransactionTotalsReportResponse', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTransactionTotalsReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTreasuryBalanceReportReplay', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTreasuryBalanceReportRequest', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTreasuryBalanceReportResponse', null, global);
goog.exportSymbol('proto.report_endpoint.ExportTreasuryBalanceReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.report_endpoint.GetAccountBalanceReportReplay', null, global);
goog.exportSymbol('proto.report_endpoint.GetAccountBalanceReportRequest', null, global);
goog.exportSymbol('proto.report_endpoint.GetAccountBalanceReportResponse', null, global);
goog.exportSymbol('proto.report_endpoint.GetAccountBalanceReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.report_endpoint.GetTransactionTotalsReportReplay', null, global);
goog.exportSymbol('proto.report_endpoint.GetTransactionTotalsReportRequest', null, global);
goog.exportSymbol('proto.report_endpoint.GetTransactionTotalsReportResponse', null, global);
goog.exportSymbol('proto.report_endpoint.GetTransactionTotalsReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.report_endpoint.GetTreasuryBalanceReportReplay', null, global);
goog.exportSymbol('proto.report_endpoint.GetTreasuryBalanceReportRequest', null, global);
goog.exportSymbol('proto.report_endpoint.GetTreasuryBalanceReportResponse', null, global);
goog.exportSymbol('proto.report_endpoint.GetTreasuryBalanceReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.report_endpoint.Label', null, global);
goog.exportSymbol('proto.report_endpoint.TransactionPeriod', null, global);
goog.exportSymbol('proto.report_endpoint.TreasuryPeriod', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetAccountBalanceReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.GetAccountBalanceReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetAccountBalanceReportRequest.displayName = 'proto.report_endpoint.GetAccountBalanceReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.GetTreasuryBalanceReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetTreasuryBalanceReportRequest.displayName = 'proto.report_endpoint.GetTreasuryBalanceReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetTransactionTotalsReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.GetTransactionTotalsReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetTransactionTotalsReportRequest.displayName = 'proto.report_endpoint.GetTransactionTotalsReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.report_endpoint.ExportTransactionTotalsReportRequest.repeatedFields_, null);
};
goog.inherits(proto.report_endpoint.ExportTransactionTotalsReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportTransactionTotalsReportRequest.displayName = 'proto.report_endpoint.ExportTransactionTotalsReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportAccountBalanceReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.report_endpoint.ExportAccountBalanceReportRequest.repeatedFields_, null);
};
goog.inherits(proto.report_endpoint.ExportAccountBalanceReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportAccountBalanceReportRequest.displayName = 'proto.report_endpoint.ExportAccountBalanceReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.report_endpoint.ExportTreasuryBalanceReportRequest.repeatedFields_, null);
};
goog.inherits(proto.report_endpoint.ExportTreasuryBalanceReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportTreasuryBalanceReportRequest.displayName = 'proto.report_endpoint.ExportTreasuryBalanceReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.Label.displayName = 'proto.report_endpoint.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.TreasuryPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.TreasuryPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.TreasuryPeriod.displayName = 'proto.report_endpoint.TreasuryPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.AccountPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.AccountPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.AccountPeriod.displayName = 'proto.report_endpoint.AccountPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.TransactionPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.TransactionPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.TransactionPeriod.displayName = 'proto.report_endpoint.TransactionPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.ExportTransactionTotalsReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportTransactionTotalsReportReplay.displayName = 'proto.report_endpoint.ExportTransactionTotalsReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportAccountBalanceReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.ExportAccountBalanceReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportAccountBalanceReportReplay.displayName = 'proto.report_endpoint.ExportAccountBalanceReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_endpoint.ExportTreasuryBalanceReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportTreasuryBalanceReportReplay.displayName = 'proto.report_endpoint.ExportTreasuryBalanceReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.report_endpoint.GetTreasuryBalanceReportReplay.repeatedFields_, null);
};
goog.inherits(proto.report_endpoint.GetTreasuryBalanceReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetTreasuryBalanceReportReplay.displayName = 'proto.report_endpoint.GetTreasuryBalanceReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetAccountBalanceReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.report_endpoint.GetAccountBalanceReportReplay.repeatedFields_, null);
};
goog.inherits(proto.report_endpoint.GetAccountBalanceReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetAccountBalanceReportReplay.displayName = 'proto.report_endpoint.GetAccountBalanceReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetTransactionTotalsReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.report_endpoint.GetTransactionTotalsReportReplay.repeatedFields_, null);
};
goog.inherits(proto.report_endpoint.GetTransactionTotalsReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetTransactionTotalsReportReplay.displayName = 'proto.report_endpoint.GetTransactionTotalsReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.report_endpoint.GetTreasuryBalanceReportResponse.oneofGroups_);
};
goog.inherits(proto.report_endpoint.GetTreasuryBalanceReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetTreasuryBalanceReportResponse.displayName = 'proto.report_endpoint.GetTreasuryBalanceReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetAccountBalanceReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.report_endpoint.GetAccountBalanceReportResponse.oneofGroups_);
};
goog.inherits(proto.report_endpoint.GetAccountBalanceReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetAccountBalanceReportResponse.displayName = 'proto.report_endpoint.GetAccountBalanceReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportAccountBalanceReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_);
};
goog.inherits(proto.report_endpoint.ExportAccountBalanceReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportAccountBalanceReportResponse.displayName = 'proto.report_endpoint.ExportAccountBalanceReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.report_endpoint.ExportTreasuryBalanceReportResponse.oneofGroups_);
};
goog.inherits(proto.report_endpoint.ExportTreasuryBalanceReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportTreasuryBalanceReportResponse.displayName = 'proto.report_endpoint.ExportTreasuryBalanceReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.report_endpoint.ExportTransactionTotalsReportResponse.oneofGroups_);
};
goog.inherits(proto.report_endpoint.ExportTransactionTotalsReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.ExportTransactionTotalsReportResponse.displayName = 'proto.report_endpoint.ExportTransactionTotalsReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_endpoint.GetTransactionTotalsReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.report_endpoint.GetTransactionTotalsReportResponse.oneofGroups_);
};
goog.inherits(proto.report_endpoint.GetTransactionTotalsReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_endpoint.GetTransactionTotalsReportResponse.displayName = 'proto.report_endpoint.GetTransactionTotalsReportResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetAccountBalanceReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetAccountBalanceReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetAccountBalanceReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetAccountBalanceReportRequest;
  return proto.report_endpoint.GetAccountBalanceReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetAccountBalanceReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetAccountBalanceReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetAccountBalanceReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetAccountBalanceReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 groupBy = 4;
 * @return {number}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue currency = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue accountType = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.getAccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportRequest.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetTreasuryBalanceReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    treasury: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 5, 0),
    treasurytype: (f = msg.getTreasurytype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetTreasuryBalanceReportRequest;
  return proto.report_endpoint.GetTreasuryBalanceReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasury(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetTreasuryBalanceReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreasury();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string treasury = 1;
 * @return {string}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.getTreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp to = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 groupBy = 5;
 * @return {number}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue treasuryType = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.getTreasurytype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportRequest.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetTransactionTotalsReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetTransactionTotalsReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetTransactionTotalsReportRequest;
  return proto.report_endpoint.GetTransactionTotalsReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetTransactionTotalsReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetTransactionTotalsReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetTransactionTotalsReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue account = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 groupBy = 4;
 * @return {number}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue currency = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportTransactionTotalsReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.report_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportTransactionTotalsReportRequest;
  return proto.report_endpoint.ExportTransactionTotalsReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 7:
      var value = new proto.report_endpoint.Label;
      reader.readMessage(value,proto.report_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportTransactionTotalsReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.report_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue account = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 groupBy = 4;
 * @return {number}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue currency = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Label labels = 7;
 * @return {!Array<!proto.report_endpoint.Label>}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.report_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.report_endpoint.Label, 7));
};


/**
 * @param {!Array<!proto.report_endpoint.Label>} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.report_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.report_endpoint.Label}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.report_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 9;
 * @return {number}
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportRequest} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportAccountBalanceReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportAccountBalanceReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 4, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.report_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportAccountBalanceReportRequest;
  return proto.report_endpoint.ExportAccountBalanceReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportAccountBalanceReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 5:
      var value = new proto.report_endpoint.Label;
      reader.readMessage(value,proto.report_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportAccountBalanceReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportAccountBalanceReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.report_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 groupBy = 4;
 * @return {number}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Label labels = 5;
 * @return {!Array<!proto.report_endpoint.Label>}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.report_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.report_endpoint.Label, 5));
};


/**
 * @param {!Array<!proto.report_endpoint.Label>} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.report_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.report_endpoint.Label}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.report_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 6;
 * @return {number}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue currency = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue accountType = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.getAccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportAccountBalanceReportRequest.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportTreasuryBalanceReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    treasury: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 5, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.report_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    treasurytype: (f = msg.getTreasurytype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportTreasuryBalanceReportRequest;
  return proto.report_endpoint.ExportTreasuryBalanceReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasury(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 6:
      var value = new proto.report_endpoint.Label;
      reader.readMessage(value,proto.report_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportTreasuryBalanceReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreasury();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.report_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string treasury = 1;
 * @return {string}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getTreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp to = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 groupBy = 5;
 * @return {number}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Label labels = 6;
 * @return {!Array<!proto.report_endpoint.Label>}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.report_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.report_endpoint.Label, 6));
};


/**
 * @param {!Array<!proto.report_endpoint.Label>} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.report_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.report_endpoint.Label}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.report_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 7;
 * @return {number}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue treasuryType = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.getTreasurytype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
*/
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportRequest} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTreasuryBalanceReportRequest.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    propartyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelvalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.Label}
 */
proto.report_endpoint.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.Label;
  return proto.report_endpoint.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.Label}
 */
proto.report_endpoint.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropartyname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelvalue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropartyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelvalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string propartyName = 1;
 * @return {string}
 */
proto.report_endpoint.Label.prototype.getPropartyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.Label} returns this
 */
proto.report_endpoint.Label.prototype.setPropartyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string labelValue = 2;
 * @return {string}
 */
proto.report_endpoint.Label.prototype.getLabelvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.report_endpoint.Label} returns this
 */
proto.report_endpoint.Label.prototype.setLabelvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue value = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.Label.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.Label} returns this
*/
proto.report_endpoint.Label.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.Label} returns this
 */
proto.report_endpoint.Label.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.Label.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.TreasuryPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.TreasuryPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.TreasuryPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.TreasuryPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    openningbalance: (f = msg.getOpenningbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalcredit: (f = msg.getTotalcredit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totaldebit: (f = msg.getTotaldebit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closingbalance: (f = msg.getClosingbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofcreditoperations: (f = msg.getTotalnumberofcreditoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalnumberofdebitoperations: (f = msg.getTotalnumberofdebitoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.TreasuryPeriod}
 */
proto.report_endpoint.TreasuryPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.TreasuryPeriod;
  return proto.report_endpoint.TreasuryPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.TreasuryPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.TreasuryPeriod}
 */
proto.report_endpoint.TreasuryPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpenningbalance(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalcredit(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotaldebit(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosingbalance(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofcreditoperations(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofdebitoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.TreasuryPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.TreasuryPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.TreasuryPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.TreasuryPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOpenningbalance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalcredit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotaldebit();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClosingbalance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofcreditoperations();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofdebitoperations();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.TreasuryPeriod.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
*/
proto.report_endpoint.TreasuryPeriod.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
 */
proto.report_endpoint.TreasuryPeriod.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TreasuryPeriod.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue openningBalance = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.TreasuryPeriod.prototype.getOpenningbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
*/
proto.report_endpoint.TreasuryPeriod.prototype.setOpenningbalance = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
 */
proto.report_endpoint.TreasuryPeriod.prototype.clearOpenningbalance = function() {
  return this.setOpenningbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TreasuryPeriod.prototype.hasOpenningbalance = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalCredit = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.TreasuryPeriod.prototype.getTotalcredit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
*/
proto.report_endpoint.TreasuryPeriod.prototype.setTotalcredit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
 */
proto.report_endpoint.TreasuryPeriod.prototype.clearTotalcredit = function() {
  return this.setTotalcredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TreasuryPeriod.prototype.hasTotalcredit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue totalDebit = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.TreasuryPeriod.prototype.getTotaldebit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
*/
proto.report_endpoint.TreasuryPeriod.prototype.setTotaldebit = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
 */
proto.report_endpoint.TreasuryPeriod.prototype.clearTotaldebit = function() {
  return this.setTotaldebit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TreasuryPeriod.prototype.hasTotaldebit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue closingBalance = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.TreasuryPeriod.prototype.getClosingbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
*/
proto.report_endpoint.TreasuryPeriod.prototype.setClosingbalance = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
 */
proto.report_endpoint.TreasuryPeriod.prototype.clearClosingbalance = function() {
  return this.setClosingbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TreasuryPeriod.prototype.hasClosingbalance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfCreditOperations = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.TreasuryPeriod.prototype.getTotalnumberofcreditoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
*/
proto.report_endpoint.TreasuryPeriod.prototype.setTotalnumberofcreditoperations = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
 */
proto.report_endpoint.TreasuryPeriod.prototype.clearTotalnumberofcreditoperations = function() {
  return this.setTotalnumberofcreditoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TreasuryPeriod.prototype.hasTotalnumberofcreditoperations = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfDebitOperations = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.TreasuryPeriod.prototype.getTotalnumberofdebitoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
*/
proto.report_endpoint.TreasuryPeriod.prototype.setTotalnumberofdebitoperations = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TreasuryPeriod} returns this
 */
proto.report_endpoint.TreasuryPeriod.prototype.clearTotalnumberofdebitoperations = function() {
  return this.setTotalnumberofdebitoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TreasuryPeriod.prototype.hasTotalnumberofdebitoperations = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.AccountPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.AccountPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.AccountPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.AccountPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    openningbalance: (f = msg.getOpenningbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalcredit: (f = msg.getTotalcredit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totaldebit: (f = msg.getTotaldebit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closingbalance: (f = msg.getClosingbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofcreditoperations: (f = msg.getTotalnumberofcreditoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalnumberofdebitoperations: (f = msg.getTotalnumberofdebitoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.AccountPeriod}
 */
proto.report_endpoint.AccountPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.AccountPeriod;
  return proto.report_endpoint.AccountPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.AccountPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.AccountPeriod}
 */
proto.report_endpoint.AccountPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpenningbalance(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalcredit(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotaldebit(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosingbalance(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofcreditoperations(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofdebitoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.AccountPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.AccountPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.AccountPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.AccountPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOpenningbalance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalcredit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotaldebit();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClosingbalance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofcreditoperations();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofdebitoperations();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.AccountPeriod.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.AccountPeriod} returns this
*/
proto.report_endpoint.AccountPeriod.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.AccountPeriod} returns this
 */
proto.report_endpoint.AccountPeriod.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.AccountPeriod.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue openningBalance = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.AccountPeriod.prototype.getOpenningbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.AccountPeriod} returns this
*/
proto.report_endpoint.AccountPeriod.prototype.setOpenningbalance = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.AccountPeriod} returns this
 */
proto.report_endpoint.AccountPeriod.prototype.clearOpenningbalance = function() {
  return this.setOpenningbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.AccountPeriod.prototype.hasOpenningbalance = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalCredit = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.AccountPeriod.prototype.getTotalcredit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.AccountPeriod} returns this
*/
proto.report_endpoint.AccountPeriod.prototype.setTotalcredit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.AccountPeriod} returns this
 */
proto.report_endpoint.AccountPeriod.prototype.clearTotalcredit = function() {
  return this.setTotalcredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.AccountPeriod.prototype.hasTotalcredit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue totalDebit = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.AccountPeriod.prototype.getTotaldebit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.AccountPeriod} returns this
*/
proto.report_endpoint.AccountPeriod.prototype.setTotaldebit = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.AccountPeriod} returns this
 */
proto.report_endpoint.AccountPeriod.prototype.clearTotaldebit = function() {
  return this.setTotaldebit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.AccountPeriod.prototype.hasTotaldebit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue closingBalance = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.AccountPeriod.prototype.getClosingbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.AccountPeriod} returns this
*/
proto.report_endpoint.AccountPeriod.prototype.setClosingbalance = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.AccountPeriod} returns this
 */
proto.report_endpoint.AccountPeriod.prototype.clearClosingbalance = function() {
  return this.setClosingbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.AccountPeriod.prototype.hasClosingbalance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfCreditOperations = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.AccountPeriod.prototype.getTotalnumberofcreditoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.AccountPeriod} returns this
*/
proto.report_endpoint.AccountPeriod.prototype.setTotalnumberofcreditoperations = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.AccountPeriod} returns this
 */
proto.report_endpoint.AccountPeriod.prototype.clearTotalnumberofcreditoperations = function() {
  return this.setTotalnumberofcreditoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.AccountPeriod.prototype.hasTotalnumberofcreditoperations = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfDebitOperations = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.AccountPeriod.prototype.getTotalnumberofdebitoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.AccountPeriod} returns this
*/
proto.report_endpoint.AccountPeriod.prototype.setTotalnumberofdebitoperations = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.AccountPeriod} returns this
 */
proto.report_endpoint.AccountPeriod.prototype.clearTotalnumberofdebitoperations = function() {
  return this.setTotalnumberofdebitoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.AccountPeriod.prototype.hasTotalnumberofdebitoperations = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.TransactionPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.TransactionPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.TransactionPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.TransactionPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currencymetadata: (f = msg.getCurrencymetadata()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalamount: (f = msg.getTotalamount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofoperations: (f = msg.getTotalnumberofoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.TransactionPeriod}
 */
proto.report_endpoint.TransactionPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.TransactionPeriod;
  return proto.report_endpoint.TransactionPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.TransactionPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.TransactionPeriod}
 */
proto.report_endpoint.TransactionPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 3:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrencymetadata(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalamount(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.TransactionPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.TransactionPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.TransactionPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.TransactionPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrencymetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalamount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofoperations();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.report_endpoint.TransactionPeriod.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
*/
proto.report_endpoint.TransactionPeriod.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
 */
proto.report_endpoint.TransactionPeriod.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TransactionPeriod.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue currency = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.TransactionPeriod.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
*/
proto.report_endpoint.TransactionPeriod.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
 */
proto.report_endpoint.TransactionPeriod.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TransactionPeriod.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.CurrencyResponse currencyMetadata = 3;
 * @return {?proto.common.CurrencyResponse}
 */
proto.report_endpoint.TransactionPeriod.prototype.getCurrencymetadata = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 3));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
*/
proto.report_endpoint.TransactionPeriod.prototype.setCurrencymetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
 */
proto.report_endpoint.TransactionPeriod.prototype.clearCurrencymetadata = function() {
  return this.setCurrencymetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TransactionPeriod.prototype.hasCurrencymetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.TransactionPeriod.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
*/
proto.report_endpoint.TransactionPeriod.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
 */
proto.report_endpoint.TransactionPeriod.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TransactionPeriod.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue totalAmount = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.TransactionPeriod.prototype.getTotalamount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
*/
proto.report_endpoint.TransactionPeriod.prototype.setTotalamount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
 */
proto.report_endpoint.TransactionPeriod.prototype.clearTotalamount = function() {
  return this.setTotalamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TransactionPeriod.prototype.hasTotalamount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfOperations = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.TransactionPeriod.prototype.getTotalnumberofoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
*/
proto.report_endpoint.TransactionPeriod.prototype.setTotalnumberofoperations = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.TransactionPeriod} returns this
 */
proto.report_endpoint.TransactionPeriod.prototype.clearTotalnumberofoperations = function() {
  return this.setTotalnumberofoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.TransactionPeriod.prototype.hasTotalnumberofoperations = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportTransactionTotalsReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: msg.getReport_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportReplay}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportTransactionTotalsReportReplay;
  return proto.report_endpoint.ExportTransactionTotalsReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportReplay}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportTransactionTotalsReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes report = 1;
 * @return {!(string|Uint8Array)}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.getReport = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes report = 1;
 * This is a type-conversion wrapper around `getReport()`
 * @return {string}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.getReport_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReport()));
};


/**
 * optional bytes report = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReport()`
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.getReport_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReport()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportReplay} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.setReport = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportReplay} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportReplay} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportAccountBalanceReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportAccountBalanceReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: msg.getReport_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportReplay}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportAccountBalanceReportReplay;
  return proto.report_endpoint.ExportAccountBalanceReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportAccountBalanceReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportReplay}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportAccountBalanceReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportAccountBalanceReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes report = 1;
 * @return {!(string|Uint8Array)}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.getReport = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes report = 1;
 * This is a type-conversion wrapper around `getReport()`
 * @return {string}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.getReport_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReport()));
};


/**
 * optional bytes report = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReport()`
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.getReport_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReport()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportReplay} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.setReport = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportReplay} returns this
*/
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportReplay} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportAccountBalanceReportReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportTreasuryBalanceReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: msg.getReport_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportReplay}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportTreasuryBalanceReportReplay;
  return proto.report_endpoint.ExportTreasuryBalanceReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportReplay}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportTreasuryBalanceReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes report = 1;
 * @return {!(string|Uint8Array)}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.getReport = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes report = 1;
 * This is a type-conversion wrapper around `getReport()`
 * @return {string}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.getReport_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReport()));
};


/**
 * optional bytes report = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReport()`
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.getReport_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReport()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportReplay} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.setReport = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportReplay} returns this
*/
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportReplay} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTreasuryBalanceReportReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetTreasuryBalanceReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.report_endpoint.TreasuryPeriod.toObject, includeInstance),
    totalcredit: (f = msg.getTotalcredit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totaldebit: (f = msg.getTotaldebit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofcreditoperations: (f = msg.getTotalnumberofcreditoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalnumberofdebitoperations: (f = msg.getTotalnumberofdebitoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetTreasuryBalanceReportReplay;
  return proto.report_endpoint.GetTreasuryBalanceReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.TreasuryPeriod;
      reader.readMessage(value,proto.report_endpoint.TreasuryPeriod.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalcredit(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotaldebit(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofcreditoperations(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofdebitoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetTreasuryBalanceReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.report_endpoint.TreasuryPeriod.serializeBinaryToWriter
    );
  }
  f = message.getTotalcredit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotaldebit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofcreditoperations();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofdebitoperations();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TreasuryPeriod report = 1;
 * @return {!Array<!proto.report_endpoint.TreasuryPeriod>}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.getReportList = function() {
  return /** @type{!Array<!proto.report_endpoint.TreasuryPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.report_endpoint.TreasuryPeriod, 1));
};


/**
 * @param {!Array<!proto.report_endpoint.TreasuryPeriod>} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.report_endpoint.TreasuryPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.report_endpoint.TreasuryPeriod}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.report_endpoint.TreasuryPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional google.protobuf.StringValue totalCredit = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.getTotalcredit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.setTotalcredit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.clearTotalcredit = function() {
  return this.setTotalcredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.hasTotalcredit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalDebit = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.getTotaldebit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.setTotaldebit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.clearTotaldebit = function() {
  return this.setTotaldebit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.hasTotaldebit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfCreditOperations = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.getTotalnumberofcreditoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.setTotalnumberofcreditoperations = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.clearTotalnumberofcreditoperations = function() {
  return this.setTotalnumberofcreditoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.hasTotalnumberofcreditoperations = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfDebitOperations = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.getTotalnumberofdebitoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.setTotalnumberofdebitoperations = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportReplay} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.clearTotalnumberofdebitoperations = function() {
  return this.setTotalnumberofdebitoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportReplay.prototype.hasTotalnumberofdebitoperations = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.report_endpoint.GetAccountBalanceReportReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetAccountBalanceReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetAccountBalanceReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetAccountBalanceReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.report_endpoint.AccountPeriod.toObject, includeInstance),
    totalcredit: (f = msg.getTotalcredit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totaldebit: (f = msg.getTotaldebit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofcreditoperations: (f = msg.getTotalnumberofcreditoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalnumberofdebitoperations: (f = msg.getTotalnumberofdebitoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetAccountBalanceReportReplay;
  return proto.report_endpoint.GetAccountBalanceReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetAccountBalanceReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.AccountPeriod;
      reader.readMessage(value,proto.report_endpoint.AccountPeriod.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalcredit(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotaldebit(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofcreditoperations(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofdebitoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetAccountBalanceReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetAccountBalanceReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetAccountBalanceReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.report_endpoint.AccountPeriod.serializeBinaryToWriter
    );
  }
  f = message.getTotalcredit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotaldebit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofcreditoperations();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofdebitoperations();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountPeriod report = 1;
 * @return {!Array<!proto.report_endpoint.AccountPeriod>}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.getReportList = function() {
  return /** @type{!Array<!proto.report_endpoint.AccountPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.report_endpoint.AccountPeriod, 1));
};


/**
 * @param {!Array<!proto.report_endpoint.AccountPeriod>} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.report_endpoint.AccountPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.report_endpoint.AccountPeriod}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.report_endpoint.AccountPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional google.protobuf.StringValue totalCredit = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.getTotalcredit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.setTotalcredit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotalcredit = function() {
  return this.setTotalcredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotalcredit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalDebit = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.getTotaldebit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.setTotaldebit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotaldebit = function() {
  return this.setTotaldebit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotaldebit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfCreditOperations = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.getTotalnumberofcreditoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.setTotalnumberofcreditoperations = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotalnumberofcreditoperations = function() {
  return this.setTotalnumberofcreditoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotalnumberofcreditoperations = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfDebitOperations = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.getTotalnumberofdebitoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.setTotalnumberofdebitoperations = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotalnumberofdebitoperations = function() {
  return this.setTotalnumberofdebitoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotalnumberofdebitoperations = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetTransactionTotalsReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetTransactionTotalsReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.report_endpoint.TransactionPeriod.toObject, includeInstance),
    totalamount: (f = msg.getTotalamount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofoperations: (f = msg.getTotalnumberofoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetTransactionTotalsReportReplay;
  return proto.report_endpoint.GetTransactionTotalsReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetTransactionTotalsReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.TransactionPeriod;
      reader.readMessage(value,proto.report_endpoint.TransactionPeriod.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalamount(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetTransactionTotalsReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetTransactionTotalsReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.report_endpoint.TransactionPeriod.serializeBinaryToWriter
    );
  }
  f = message.getTotalamount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofoperations();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionPeriod report = 1;
 * @return {!Array<!proto.report_endpoint.TransactionPeriod>}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.getReportList = function() {
  return /** @type{!Array<!proto.report_endpoint.TransactionPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.report_endpoint.TransactionPeriod, 1));
};


/**
 * @param {!Array<!proto.report_endpoint.TransactionPeriod>} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.report_endpoint.TransactionPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.report_endpoint.TransactionPeriod}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.report_endpoint.TransactionPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional google.protobuf.StringValue totalAmount = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.getTotalamount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.setTotalamount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.clearTotalamount = function() {
  return this.setTotalamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.hasTotalamount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfOperations = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.getTotalnumberofoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.setTotalnumberofoperations = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportReplay} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.clearTotalnumberofoperations = function() {
  return this.setTotalnumberofoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportReplay.prototype.hasTotalnumberofoperations = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.report_endpoint.GetTreasuryBalanceReportResponse.ResultCase}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.report_endpoint.GetTreasuryBalanceReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.report_endpoint.GetTreasuryBalanceReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetTreasuryBalanceReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.report_endpoint.GetTreasuryBalanceReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportResponse}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetTreasuryBalanceReportResponse;
  return proto.report_endpoint.GetTreasuryBalanceReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportResponse}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.GetTreasuryBalanceReportReplay;
      reader.readMessage(value,proto.report_endpoint.GetTreasuryBalanceReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetTreasuryBalanceReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetTreasuryBalanceReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.report_endpoint.GetTreasuryBalanceReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuryBalanceReportReplay success = 1;
 * @return {?proto.report_endpoint.GetTreasuryBalanceReportReplay}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.report_endpoint.GetTreasuryBalanceReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.report_endpoint.GetTreasuryBalanceReportReplay, 1));
};


/**
 * @param {?proto.report_endpoint.GetTreasuryBalanceReportReplay|undefined} value
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportResponse} returns this
*/
proto.report_endpoint.GetTreasuryBalanceReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.report_endpoint.GetTreasuryBalanceReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTreasuryBalanceReportResponse} returns this
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTreasuryBalanceReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.report_endpoint.GetAccountBalanceReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.report_endpoint.GetAccountBalanceReportResponse.ResultCase}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.report_endpoint.GetAccountBalanceReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.report_endpoint.GetAccountBalanceReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetAccountBalanceReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetAccountBalanceReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetAccountBalanceReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.report_endpoint.GetAccountBalanceReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetAccountBalanceReportResponse}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetAccountBalanceReportResponse;
  return proto.report_endpoint.GetAccountBalanceReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetAccountBalanceReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetAccountBalanceReportResponse}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.GetAccountBalanceReportReplay;
      reader.readMessage(value,proto.report_endpoint.GetAccountBalanceReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetAccountBalanceReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetAccountBalanceReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetAccountBalanceReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.report_endpoint.GetAccountBalanceReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountBalanceReportReplay success = 1;
 * @return {?proto.report_endpoint.GetAccountBalanceReportReplay}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.report_endpoint.GetAccountBalanceReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.report_endpoint.GetAccountBalanceReportReplay, 1));
};


/**
 * @param {?proto.report_endpoint.GetAccountBalanceReportReplay|undefined} value
 * @return {!proto.report_endpoint.GetAccountBalanceReportResponse} returns this
*/
proto.report_endpoint.GetAccountBalanceReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.report_endpoint.GetAccountBalanceReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetAccountBalanceReportResponse} returns this
 */
proto.report_endpoint.GetAccountBalanceReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetAccountBalanceReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.report_endpoint.ExportAccountBalanceReportResponse.ResultCase}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.report_endpoint.ExportAccountBalanceReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportAccountBalanceReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportAccountBalanceReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.report_endpoint.ExportAccountBalanceReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportResponse}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportAccountBalanceReportResponse;
  return proto.report_endpoint.ExportAccountBalanceReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportAccountBalanceReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportResponse}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.ExportAccountBalanceReportReplay;
      reader.readMessage(value,proto.report_endpoint.ExportAccountBalanceReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportAccountBalanceReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportAccountBalanceReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.report_endpoint.ExportAccountBalanceReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportAccountBalanceReportReplay success = 1;
 * @return {?proto.report_endpoint.ExportAccountBalanceReportReplay}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.report_endpoint.ExportAccountBalanceReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.report_endpoint.ExportAccountBalanceReportReplay, 1));
};


/**
 * @param {?proto.report_endpoint.ExportAccountBalanceReportReplay|undefined} value
 * @return {!proto.report_endpoint.ExportAccountBalanceReportResponse} returns this
*/
proto.report_endpoint.ExportAccountBalanceReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportAccountBalanceReportResponse} returns this
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportAccountBalanceReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.report_endpoint.ExportTreasuryBalanceReportResponse.ResultCase}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.report_endpoint.ExportTreasuryBalanceReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.report_endpoint.ExportTreasuryBalanceReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportTreasuryBalanceReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.report_endpoint.ExportTreasuryBalanceReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportResponse}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportTreasuryBalanceReportResponse;
  return proto.report_endpoint.ExportTreasuryBalanceReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportResponse}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.ExportTreasuryBalanceReportReplay;
      reader.readMessage(value,proto.report_endpoint.ExportTreasuryBalanceReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportTreasuryBalanceReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportTreasuryBalanceReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.report_endpoint.ExportTreasuryBalanceReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportTreasuryBalanceReportReplay success = 1;
 * @return {?proto.report_endpoint.ExportTreasuryBalanceReportReplay}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.report_endpoint.ExportTreasuryBalanceReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.report_endpoint.ExportTreasuryBalanceReportReplay, 1));
};


/**
 * @param {?proto.report_endpoint.ExportTreasuryBalanceReportReplay|undefined} value
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportResponse} returns this
*/
proto.report_endpoint.ExportTreasuryBalanceReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.report_endpoint.ExportTreasuryBalanceReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTreasuryBalanceReportResponse} returns this
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTreasuryBalanceReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.report_endpoint.ExportTransactionTotalsReportResponse.ResultCase}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.report_endpoint.ExportTransactionTotalsReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.report_endpoint.ExportTransactionTotalsReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.ExportTransactionTotalsReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.report_endpoint.ExportTransactionTotalsReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportResponse}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.ExportTransactionTotalsReportResponse;
  return proto.report_endpoint.ExportTransactionTotalsReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportResponse}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.ExportTransactionTotalsReportReplay;
      reader.readMessage(value,proto.report_endpoint.ExportTransactionTotalsReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.ExportTransactionTotalsReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.ExportTransactionTotalsReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.report_endpoint.ExportTransactionTotalsReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportTransactionTotalsReportReplay success = 1;
 * @return {?proto.report_endpoint.ExportTransactionTotalsReportReplay}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.report_endpoint.ExportTransactionTotalsReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.report_endpoint.ExportTransactionTotalsReportReplay, 1));
};


/**
 * @param {?proto.report_endpoint.ExportTransactionTotalsReportReplay|undefined} value
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportResponse} returns this
*/
proto.report_endpoint.ExportTransactionTotalsReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.report_endpoint.ExportTransactionTotalsReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.ExportTransactionTotalsReportResponse} returns this
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.ExportTransactionTotalsReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.report_endpoint.GetTransactionTotalsReportResponse.ResultCase}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.report_endpoint.GetTransactionTotalsReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.report_endpoint.GetTransactionTotalsReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.report_endpoint.GetTransactionTotalsReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_endpoint.GetTransactionTotalsReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.report_endpoint.GetTransactionTotalsReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportResponse}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_endpoint.GetTransactionTotalsReportResponse;
  return proto.report_endpoint.GetTransactionTotalsReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_endpoint.GetTransactionTotalsReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportResponse}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.report_endpoint.GetTransactionTotalsReportReplay;
      reader.readMessage(value,proto.report_endpoint.GetTransactionTotalsReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_endpoint.GetTransactionTotalsReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_endpoint.GetTransactionTotalsReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.report_endpoint.GetTransactionTotalsReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTransactionTotalsReportReplay success = 1;
 * @return {?proto.report_endpoint.GetTransactionTotalsReportReplay}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.report_endpoint.GetTransactionTotalsReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.report_endpoint.GetTransactionTotalsReportReplay, 1));
};


/**
 * @param {?proto.report_endpoint.GetTransactionTotalsReportReplay|undefined} value
 * @return {!proto.report_endpoint.GetTransactionTotalsReportResponse} returns this
*/
proto.report_endpoint.GetTransactionTotalsReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.report_endpoint.GetTransactionTotalsReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.report_endpoint.GetTransactionTotalsReportResponse} returns this
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.report_endpoint.GetTransactionTotalsReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.report_endpoint);
